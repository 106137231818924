import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { IconArrowUp, IconArrowDown } from "@tabler/icons-react";

import {
    type TuneThreadReorderItemsMutationVariables,
    reorderItemsMutation,
    type TuneThreadReorderItemsMutation,
} from "./queries";
import ActionButton from "../../ActionButton";

interface IProps {
    tuneThread: {
        id: number;
    };
    tuneThreadItems: {
        id: number;
    }[];
    tuneThreadItemId: number;
}

function ReorderItemsButtons({ tuneThread, tuneThreadItems, tuneThreadItemId }: IProps): JSX.Element {
    const [reorderResult, executeReorder] = useMutation<
        TuneThreadReorderItemsMutation,
        TuneThreadReorderItemsMutationVariables
    >(reorderItemsMutation);

    const reorder = async (direction: "up" | "down") => {
        const itemIndex = tuneThreadItems.findIndex((item) => item.id === tuneThreadItemId);
        const itemOrders = tuneThreadItems.map((item, index) => {
            if (item.id === tuneThreadItemId) {
                if (direction === "up") {
                    return [item.id, index - 1];
                }
                return [item.id, index + 1];
            }
            if (direction === "up" && index === itemIndex - 1) {
                return [item.id, index + 1];
            }
            if (direction === "down" && index === itemIndex + 1) {
                return [item.id, index - 1];
            }
            return [item.id, index];
        });
        const result = await executeReorder({
            data: {
                tuneThreadId: tuneThread.id,
                itemOrders,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to move item",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Item moved",
                message: "Item moved",
            });
        }
    };

    return (
        <>
            <ActionButton
                onClick={() => reorder("up")}
                label="Move up"
                icon={<IconArrowUp stroke={1} />}
                disabled={reorderResult.fetching}
            />
            <ActionButton
                onClick={() => reorder("down")}
                label="Move down"
                icon={<IconArrowDown stroke={1} />}
                disabled={reorderResult.fetching}
            />
        </>
    );
}

export default ReorderItemsButtons;
