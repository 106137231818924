import { Route, Routes } from "react-router-dom";

import Add from "./Add";
import Transcription from "./Transcription";
import Transcriptions from "./Transcriptions";

function TranscriptionsRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/add" element={<Add />} />
            <Route path="/:transcriptionId" element={<Transcription />} />
            <Route path="/" element={<Transcriptions />} />
        </Routes>
    );
}

export default TranscriptionsRoutes;
