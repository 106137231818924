import { gql } from "urql";

// Get Document by id query

export const documentQuery = gql`
    query DocumentQuery($documentId:Int!) {
        document(documentId:$documentId) {
            id
            documentType
            processingStatus
            name
            tokens
            content
            extractedText
            language
            url
            documentChunks {
                edges {
                    node {
                        id
                        textContent
                    }
                }
            }
            file {
                id
                fileType
                fileName
                src
            }
            tags
        }
    }
`;

export type * from "./__generated__/document-query";

// Documents page query

export const documentPageQuery = gql`
    query DocumentPageQuery($page:Int!) {
        documentPage(page:$page) {
            items {
                id
                name
                created
                tokens
                documentType
                processingStatus
                language
                tags
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/document-page-query";

// Search Documents query

export const searchQuery = gql`
    query DocumentSearchQuery($query:String!$page:Int!,$tags:[String!],$languages:[Language!],$name:String) {
        documentSearch(query:$query,page:$page,tags:$tags,languages:$languages,name:$name) {
            items {
                id
                documentType
                name
                matchingChunkText
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/document-search-query";

// Add Document mutation

export const addMutation = gql`
    mutation DocumentAddMutation($data:DocumentCreateInput!) {
        documentCreate(data:$data) {
            id
            documentType
            name
            content
            extractedText
            url
            documentChunks {
                edges {
                    node {
                        id
                        textContent
                    }
                }
            }
            file {
                id
                fileType
                fileName
                src
            }
            tags
        }
    }
`;

export type * from "./__generated__/document-add-mutation";

// Delete Document mutation

export const deleteMutation = gql`
    mutation DocumentDeleteMutation($documentId:Int!) {
        documentDelete(documentId:$documentId) {
            id
            name
        }
    }
`;

export type * from "./__generated__/document-delete-mutation";

// Edit Document mutation

export const editMutation = gql`
    mutation DocumentEditMutation($data:DocumentEditInput!) {
        documentEdit(data:$data) {
            id
            documentType
            name
            content
            url
            extractedText
            documentChunks {
                edges {
                    node {
                        id
                        textContent
                    }
                }
            }
            file {
                id
                fileType
                fileName
                src
            }
            tags
        }
    }
`;

export type * from "./__generated__/document-edit-mutation";

// Porcess all Document mutation

export const processAllMutation = gql`
    mutation DocumentProcessAllMutation {
        documentProcessAll {
            id
            documentType
            name
            content
            url
            extractedText
            documentChunks {
                edges {
                    node {
                        id
                        textContent
                    }
                }
            }
            file {
                id
                fileType
                fileName
                src
            }
            tags
        }
    }
`;

export type * from "./__generated__/document-process-all-mutation";
