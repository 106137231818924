import { useForm } from "@mantine/form";
import { Button, TextInput, MultiSelect } from "@mantine/core";

import { LANGUAGES, type Language, languageLabel } from "../utils";
import TagsInput from "../TagsInput";
import classes from "./SearchForm.module.css";

export interface IFormValues {
    query: string;
    tags: string[];
    languages: Language[];
    name: string;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    initialValues?: IFormValues;
    fetching: boolean;
}

function SearchForm({ handleSubmit, initialValues, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: initialValues ?? {
            query: "",
            tags: [],
            languages: [],
            name: "",
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <div className={classes.grid}>
                <TextInput placeholder="Name" {...form.getInputProps("name")} />
                <TagsInput form={form} label={""} placeholder="Tags" />
                <MultiSelect
                    placeholder="Languages"
                    data={LANGUAGES.map((lang) => ({ value: lang, label: languageLabel(lang) }))}
                    clearable
                    {...form.getInputProps("languages")}
                />
                <TextInput placeholder="Content" {...form.getInputProps("query")} className={classes.content} />
                <Button type="submit" disabled={fetching}>
                    Search
                </Button>
            </div>
        </form>
    );
}

export default SearchForm;
