import { useQuery } from "urql";
import { Box, Button, Table, Title, Pagination, Radio, Group } from "@mantine/core";
import { Link } from "react-router-dom";
import { useState } from "react";

import { threadPageQuery, type ThreadPageQuery, type ThreadPageQueryVariables } from "./queries";
import classes from "./PreviousThreads.module.css";
import { useInputState } from "@mantine/hooks";
import DeleteButton from "./DeleteButton";
import Loading from "../Loading";

function PreviousThreads(): JSX.Element {
    const [filter, setFilter] = useInputState("MINE");
    const [page, setPage] = useState(1);
    const [result] = useQuery<ThreadPageQuery, ThreadPageQueryVariables>({
        query: threadPageQuery,
        variables: {
            page,
            filter: filter as ThreadPageQueryVariables["filter"],
        },
    });

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.threadPage.maxPage === 0) {
        main = <Box>No previous threads</Box>;
    } else {
        main = (
            <>
                <Radio.Group value={filter} onChange={setFilter} name="shownThreads" label="Conversations to show">
                    <Group mt="xs">
                        <Radio value="MINE" label="Mine" />
                        <Radio value="OTHERS" label="Other's" />
                        <Radio value="ALL" label="All" />
                    </Group>
                </Radio.Group>
                <Pagination total={result.data.threadPage.maxPage} value={page} onChange={setPage} />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>AI personality</Table.Th>
                            <Table.Th>Creator</Table.Th>
                            <Table.Th>Created</Table.Th>
                            <Table.Th>Tokens</Table.Th>
                            <Table.Th colSpan={3} />
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {result.data.threadPage.items.map((thread) => (
                            <Table.Tr key={thread.id}>
                                <Table.Td>{thread.threadName || "New chat"}</Table.Td>
                                <Table.Td>{thread.aiPersonality?.name}</Table.Td>
                                <Table.Td>
                                    {thread.user ? `${thread.user.firstName} ${thread.user.lastName}` : ""}
                                </Table.Td>
                                <Table.Td>{new Date(thread.created).toLocaleString("en-GB")}</Table.Td>
                                <Table.Td>{thread.tokens}</Table.Td>
                                <Table.Td>
                                    <Button component={Link} to={`../${thread.id}`}>
                                        Open
                                    </Button>
                                </Table.Td>
                                <Table.Td>
                                    <DeleteButton thread={thread} />
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
                <Pagination total={result.data.threadPage.maxPage} value={page} onChange={setPage} />
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>Previous AI chat conversations</Title>
            {main}
            <Link to="..">Back</Link>
        </Box>
    );
}

export default PreviousThreads;
