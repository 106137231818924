import { gql } from "urql";

// OpenAI cache info

export const openaiCacheInfoQuery = gql`
    query OpenaiCacheInfoQuery {
        openaiCacheSize
        openaiCacheNames 
        openaiCacheStats {
            date {
                month
                count
            }
            personality {
                name
                count
            }
        }
    }
`;

export type * from "./__generated__/openai-cache-info-query";

// OpenAI clear cache mutation

export const openaiCacheClearMutation = gql`
    mutation OpenaiCacheClearMutation($keep:Int!,$personality:String) {
        openaiCacheClear(keep:$keep,personality:$personality)
    }
`;

export type * from "./__generated__/openai-cache-clear-mutation";
