import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons-react";
import { useMutation } from "urql";
import ActionButton from "../../ActionButton";
import type { ItemRole } from "../../utils";
import ItemForm, { type IFormValues } from "./ItemForm";
import {
    type TuneThreadUpdateItemMutation,
    type TuneThreadUpdateItemMutationVariables,
    updateItemMutation,
} from "./queries";

interface IProps {
    tuneThreadItem: {
        id: number;
        role: ItemRole;
        content: string;
        context: boolean;
        toolCallId: string | null;
        toolName: string | null;
        toolArguments: string | null;
    };
}

function EditItemButton({ tuneThreadItem }: IProps): JSX.Element {
    const [updateResult, executeUpdate] = useMutation<
        TuneThreadUpdateItemMutation,
        TuneThreadUpdateItemMutationVariables
    >(updateItemMutation);

    const edit = async (values: IFormValues) => {
        const result = await executeUpdate({
            data: {
                tuneThreadItemId: tuneThreadItem.id,
                item: {
                    role: values.role,
                    content: values.content,
                    context: tuneThreadItem.context,
                    toolCallId: values.toolCallId || null,
                    toolName: values.toolName || null,
                    toolArguments:
                        Object.keys(values.toolArguments).length > 0 ? JSON.stringify(values.toolArguments) : null,
                },
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to update item",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Item updated",
                message: "Item updated",
            });
        }
    };

    const initialValues: IFormValues = {
        role: tuneThreadItem.role,
        content: tuneThreadItem.content,
        toolCallId: tuneThreadItem.toolCallId || "",
        toolName: (tuneThreadItem.toolName || "") as "" | "documentSearch",
        toolArguments: tuneThreadItem.toolArguments ? JSON.parse(tuneThreadItem.toolArguments) : {},
    };

    const onClick = () =>
        modals.open({
            title: "Edit item",
            size: "xl",
            children: (
                <ItemForm
                    fetching={updateResult.fetching}
                    handleSubmit={(values) => {
                        edit(values);
                        modals.closeAll();
                    }}
                    initialValues={initialValues}
                />
            ),
        });

    return (
        <ActionButton
            onClick={onClick}
            label="Edit item"
            icon={<IconEdit stroke={1} />}
            disabled={updateResult.fetching}
        />
    );
}

export default EditItemButton;
