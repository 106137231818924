import { ActionIcon, Anchor, Badge, Box, Group, Text, Title } from "@mantine/core";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { IconArrowBarDown, IconArrowBarToUp, IconCopy } from "@tabler/icons-react";
import { useMemo } from "react";
import Markdown from "react-markdown";
import { Link, useLocation, useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { useQuery } from "urql";
import ActionButton from "../ActionButton";
import InfoNode from "../InfoNode";
import Loading from "../Loading";
import { documentTypeLabel, languageLabel, processingStatusLabel, useProcessing } from "../utils";
import classes from "./Document.module.css";
import { type DocumentQuery, type DocumentQueryVariables, documentQuery } from "./queries";

const EXTRACT_MARKDOWN = ["PDF", "WORD", "POWERPOINT", "WEBPAGE"];

function Document(): JSX.Element {
    const { documentId } = useParams();
    const { search } = useLocation();
    const [extractedOpened, extractedFuncs] = useDisclosure(false);
    const [chunksOpened, chunksFuncs] = useDisclosure(false);
    const query = useMemo(() => new URLSearchParams(search).get("query"), [search]);
    const backLink = query ? `../search?query=${query}` : "..";
    const [result, execute] = useQuery<DocumentQuery, DocumentQueryVariables>({
        query: documentQuery,
        variables: {
            documentId: Number.parseInt(documentId!),
        },
    });
    const clipboard = useClipboard();

    useProcessing(result, execute, (result) => result.data?.document?.processingStatus === "PROCESSING");

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const document = result.data?.document;
        if (!document) {
            main = <Box>Document not found</Box>;
        } else {
            const showFileInput = ["PDF", "WORD", "POWERPOINT"].includes(document.documentType);

            const copyExtracted = () => {
                clipboard.copy(document.extractedText);
            };

            main = (
                <>
                    <InfoNode title="Processing type">
                        <Text span>{documentTypeLabel(document.documentType)}</Text>
                    </InfoNode>
                    <InfoNode title="Document status">
                        <Text span>{processingStatusLabel(document.processingStatus)}</Text>
                    </InfoNode>
                    <InfoNode title="Name">
                        <Text span>{document.name}</Text>
                    </InfoNode>
                    <InfoNode title="Tokens">
                        <Text span>{document.tokens}</Text>
                    </InfoNode>
                    <InfoNode title="Language">
                        <Text span>{languageLabel(document.language)}</Text>
                    </InfoNode>
                    <InfoNode title="Tags">
                        <Group gap="xs">
                            {document.tags.map((tag) => (
                                <Badge key={tag} size="xs">
                                    {tag}
                                </Badge>
                            ))}
                        </Group>
                    </InfoNode>
                    {document.documentType === "PLAINTEXT" && (
                        <InfoNode title="Content">
                            <Text style={{ whiteSpace: "pre-wrap" }}>{document.content}</Text>
                        </InfoNode>
                    )}
                    {document.documentType === "WEBPAGE" && (
                        <InfoNode title="URL">
                            <Text>
                                <Anchor href={document.file?.src} target="_blank">
                                    {document.url}
                                </Anchor>
                            </Text>
                        </InfoNode>
                    )}
                    {showFileInput && (
                        <InfoNode title="File">
                            <Text>
                                <Anchor href={document.file?.src} target="_blank">
                                    {document.file?.fileName}
                                </Anchor>
                            </Text>
                        </InfoNode>
                    )}
                    <InfoNode title="Extracted text">
                        <ActionIcon.Group className={classes.icons}>
                            {!extractedOpened && (
                                <ActionButton
                                    onClick={extractedFuncs.open}
                                    label="Show extracted text"
                                    icon={<IconArrowBarDown stroke={1} />}
                                />
                            )}
                            {extractedOpened && (
                                <ActionButton
                                    onClick={extractedFuncs.close}
                                    label="Hide extracted text"
                                    icon={<IconArrowBarToUp stroke={1} />}
                                />
                            )}
                            <ActionButton
                                onClick={copyExtracted}
                                label="Copy extracted text"
                                icon={<IconCopy stroke={1} />}
                            />
                        </ActionIcon.Group>
                        {extractedOpened &&
                            (EXTRACT_MARKDOWN.includes(document.documentType) ? (
                                <Markdown className={classes.markdownText} remarkPlugins={[remarkGfm]}>
                                    {document.extractedText}
                                </Markdown>
                            ) : (
                                <Text style={{ whiteSpace: "pre-wrap" }}>{document.extractedText}</Text>
                            ))}
                    </InfoNode>
                    <InfoNode title={`Text chunks (${document.documentChunks.edges.length})`}>
                        <ActionIcon.Group className={classes.icons}>
                            {!chunksOpened && (
                                <ActionButton
                                    onClick={chunksFuncs.open}
                                    label="Show chunks"
                                    icon={<IconArrowBarDown stroke={1} />}
                                />
                            )}
                            {chunksOpened && (
                                <ActionButton
                                    onClick={chunksFuncs.close}
                                    label="Hide chunks"
                                    icon={<IconArrowBarToUp stroke={1} />}
                                />
                            )}
                        </ActionIcon.Group>
                        {chunksOpened &&
                            document.documentChunks.edges.map(({ node }) => (
                                <Text key={node.id} className={classes.chunkNode} style={{ whiteSpace: "pre-wrap" }}>
                                    {node.textContent}
                                </Text>
                            ))}
                    </InfoNode>
                </>
            );
        }
    }

    return (
        <div className={classes.grid}>
            <Title order={2}>Document details</Title>
            {main}
            <Link to={backLink}>Back</Link>
        </div>
    );
}

export default Document;
