import { Title } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";

import UserForm, { type IFormValues } from "./UserForm";
import { type UserAddMutationVariables, addMutation, type UserAddMutation } from "./queries";

function Add(): JSX.Element {
    const navigate = useNavigate();
    const [addResult, executeAdd] = useMutation<UserAddMutation, UserAddMutationVariables>(addMutation);

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeAdd({
            data: {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save user",
                message: result.error.message,
                color: "red",
            });
        } else {
            notifications.show({
                title: "User saved with password",
                message: result.data?.userCreate.password,
            });
            navigate("..");
        }
    };

    return (
        <>
            <Title order={2}>Add user</Title>
            <UserForm handleSubmit={handleSubmit} fetching={addResult.fetching} />
            <Link to="..">Back</Link>
        </>
    );
}

export default Add;
