import { useInputState } from "@mantine/hooks";
import { Textarea, Box, ActionIcon } from "@mantine/core";
import { IconPlayerPlay } from "@tabler/icons-react";
import type { KeyboardEvent, MouseEvent } from "react";

import classes from "./PromptInput.module.css";

interface IProps {
    generate: (prompt: string) => Promise<void>;
}

function PromptInput({ generate }: IProps): JSX.Element {
    const [value, handleChange] = useInputState("");

    const handleOnKeyDown = (event: KeyboardEvent) => {
        if (!value) {
            return;
        }
        if (event.key === "Enter" && !event.shiftKey) {
            generate(value);
            event.preventDefault();
        }
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (!value) {
            return;
        }
        generate(value);
    };

    return (
        <Box className={classes.box}>
            <Textarea
                placeholder="Describe image"
                autosize
                value={value}
                onChange={handleChange}
                onKeyDown={handleOnKeyDown}
                styles={{
                    input: {
                        paddingRight: 38,
                    },
                }}
            />
            <ActionIcon className={classes.button} size={36} onClick={handleClick}>
                <IconPlayerPlay />
            </ActionIcon>
        </Box>
    );
}

export default PromptInput;
