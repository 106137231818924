import { Box, Divider, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import type { JSX } from "react";

interface IProps {
    judgement: {
        reasoning: string;
        score: number;
    };
}

function Judgement({ judgement }: IProps): JSX.Element {
    const [opened, { toggle }] = useDisclosure(false);
    return (
        <>
            <Divider color="gray" label="LLM Judgement" />
            <Box>
                <Text fw="bold" mb="0" onClick={toggle}>
                    Score: {judgement.score}
                </Text>
                {opened && <Text mb="0">{judgement.reasoning}</Text>}
            </Box>
        </>
    );
}

export default Judgement;
