import { Box, Button, Group, LoadingOverlay, Select, Stack, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import type { ItemRole } from "../../utils";
import ToolArgumentsInput from "./ToolArgumentsInput";

export interface IFormValues {
    role: ItemRole;
    content: string;
    toolCallId: string;
    toolName: "" | "documentSearch";
    toolArguments: Record<string, string>;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    initialValues?: IFormValues;
    fetching: boolean;
}

const ROLES = ["USER", "ASSISTANT", "TOOL"];
const TOOL_NAMES = ["", "documentSearch"] as const;

function ItemForm({ handleSubmit, initialValues, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: initialValues ?? {
            role: "USER" as ItemRole,
            content: "",
            toolCallId: "",
            toolName: "",
            toolArguments: {},
        },
        validate: {
            role: (value) => (ROLES.includes(value) ? null : "Required"),
            content: (value, values) => {
                if (values.role === "ASSISTANT" && values.toolName !== "") {
                    return null;
                }
                return value ? null : "Required";
            },
            toolCallId: (value, values) => {
                if (values.toolName !== "" && !value) {
                    return "Required";
                }
                return null;
            },
            toolArguments: (value, values) => {
                if (values.toolName !== "" && Object.keys(value).length === 0) {
                    return "Required";
                }
                return null;
            },
        },
    });

    const showToolName = form.values.role === "ASSISTANT" || form.values.role === "TOOL";
    const showToolCallId = form.values.toolName !== "";
    const showToolArguments = form.values.toolName !== "";

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto">
                <LoadingOverlay visible={fetching} />
                <Select withAsterisk label="Item role" data={ROLES} {...form.getInputProps("role")} />
                <Textarea withAsterisk autosize minRows={3} label="Item content" {...form.getInputProps("content")} />
                {showToolName && <Select label="Tool Name" data={TOOL_NAMES} {...form.getInputProps("toolName")} />}
                {showToolCallId && (
                    <TextInput withAsterisk label="Tool Call ID" {...form.getInputProps("toolCallId")} />
                )}
                {showToolArguments && (
                    <Box>
                        <Box mb="xs">Tool Arguments</Box>
                        <ToolArgumentsInput
                            value={form.values.toolArguments}
                            onChange={(value) => form.setFieldValue("toolArguments", value)}
                        />
                    </Box>
                )}
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={fetching}>
                        Save
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default ItemForm;
