import { gql } from "urql";

// User page query

export const userPageQuery = gql`
    query UserPageQuery($page:Int!) {
        userPage(page:$page) {
            items {
                id
            email
            created
            firstName
            lastName
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/user-page-query";

// Add user mutation

export const addMutation = gql`
    mutation UserAddMutation($data:UserCreateInput!) {
        userCreate(data:$data) {
            password
            user {
                id
            }
        }
    }
`;

export type * from "./__generated__/user-add-mutation";

// Get user query

export const userQuery = gql`
    query UserQuery($userId:Int!) {
        user(userId:$userId) {
            id
            email
            firstName
            lastName
        }
    }
`;

export type * from "./__generated__/user-query";

// Delete user mutation

export const deleteMutation = gql`
    mutation UserDeleteMutation($userId:Int!) {
        userDelete(userId:$userId) {
            id
            firstName
            lastName
        }
    }
`;

export type * from "./__generated__/user-delete-mutation";

// Edit user mutation

export const editMutation = gql`
    mutation UserEditMutation($data:UserEditInput!) {
        userEdit(data:$data) {
            id
            firstName
            lastName
        }
    }
`;

export type * from "./__generated__/user-edit-mutation";

// Update password mutation

export const passwordMutation = gql`
    mutation UserChangePasswordMutation($userId:Int!,$password:String!) {
        userChangePassword(userId:$userId,password:$password) {
            password
            user {
                firstName
                lastName
            }
        }
    }
`;

export type * from "./__generated__/user-change-password-mutation";
