import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text, Button } from "@mantine/core";
import { modals } from "@mantine/modals";

import { type AiPersonalityDeleteMutationVariables, deleteMutation, type AiPersonalityDeleteMutation } from "./queries";

interface IProps {
    aiPersonality: {
        id: number;
        name: string;
    };
}

function DeleteButton({ aiPersonality }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<
        AiPersonalityDeleteMutation,
        AiPersonalityDeleteMutationVariables
    >(deleteMutation);

    const doDelete = async () => {
        const result = await executeDelete({ aiPersonalityId: aiPersonality.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete AI personality",
                message: result.error.message,
            });
        } else {
            const aiPersonality = result.data!.aiPersonalityDelete;
            notifications.show({
                title: "AI personality deleted",
                message: `AI personality ${aiPersonality.name} deleted`,
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete AI personality?",
            children: <Text size="sm">Are you sure you want to delete AI personality {aiPersonality.name}?</Text>,
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <Button onClick={onClick} disabled={deleteResult.fetching} color="red">
            Delete
        </Button>
    );
}

export default DeleteButton;
