import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text, Button } from "@mantine/core";
import { modals } from "@mantine/modals";

import { type TuneThreadDeleteMutationVariables, deleteMutation, type TuneThreadDeleteMutation } from "./queries";

interface IProps {
    tuneThread: {
        id: number;
    };
    disabled: boolean;
}

function DeleteThreadButton({ tuneThread, disabled }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<TuneThreadDeleteMutation, TuneThreadDeleteMutationVariables>(
        deleteMutation,
    );

    const doDelete = async () => {
        const result = await executeDelete({ tuneThreadId: tuneThread.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete thread",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Thread deleted",
                message: "Thread deleted",
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete thread?",
            children: <Text size="sm">Are you sure you want to delete this thread?</Text>,
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <Button onClick={onClick} disabled={disabled || deleteResult.fetching} color="red">
            Delete
        </Button>
    );
}

export default DeleteThreadButton;
