import { Box, Title } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { notifications } from "@mantine/notifications";

import UserForm, { type IFormValues } from "./UserForm";
import {
    type UserEditMutationVariables,
    editMutation,
    type UserEditMutation,
    type UserQueryVariables,
    userQuery,
    type UserQuery,
} from "./queries";
import Loading from "../Loading";

function Edit(): JSX.Element {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [editResult, executeEdit] = useMutation<UserEditMutation, UserEditMutationVariables>(editMutation);
    const [result] = useQuery<UserQuery, UserQueryVariables>({
        query: userQuery,
        variables: {
            userId: Number.parseInt(userId!),
        },
    });

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeEdit({
            data: {
                id: Number.parseInt(userId!),
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save user",
                message: result.error.message,
            });
        } else {
            const user = result.data!.userEdit;
            notifications.show({
                title: "User saved",
                message: `User ${user.firstName} ${user.lastName} updated`,
            });
            navigate("..");
        }
    };

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const user = result.data?.user;
        if (!user) {
            main = <Box>User not found</Box>;
        } else {
            const initialValues = {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            };
            main = (
                <UserForm handleSubmit={handleSubmit} initialValues={initialValues} fetching={editResult.fetching} />
            );
        }
    }

    return (
        <>
            <Title order={2}>Edit user</Title>
            {main}
            <Link to="..">Back</Link>
        </>
    );
}

export default Edit;
