import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";

import { type TranscriptionDeleteMutationVariables, deleteMutation, type TranscriptionDeleteMutation } from "./queries";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

interface IProps {
    transcription: {
        id: number;
    };
}

function DeleteButton({ transcription }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<
        TranscriptionDeleteMutation,
        TranscriptionDeleteMutationVariables
    >(deleteMutation);

    const doDelete = async () => {
        const result = await executeDelete({ transcriptionId: transcription.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete Transcription",
                message: result.error.message,
            });
        } else {
            const transcription = result.data!.transcriptionDelete;
            notifications.show({
                title: "Transcription deleted",
                message: "Transcription deleted",
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete Transcription?",
            children: <Text size="sm">Are you sure you want to delete the transcription?</Text>,
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <Button onClick={onClick} disabled={deleteResult.fetching} color="red">
            Delete
        </Button>
    );
}

export default DeleteButton;
