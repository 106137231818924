import { Button, NumberInput, Select, Stack } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { type UseQueryExecute, useMutation } from "urql";
import {
    type OpenaiCacheClearMutation,
    type OpenaiCacheClearMutationVariables,
    openaiCacheClearMutation,
} from "./queries";

interface IProps {
    refetch: UseQueryExecute;
    names: string[];
}

function ClearCacheModal({ refetch, names }: IProps): JSX.Element {
    const [result, executeClear] = useMutation<OpenaiCacheClearMutation, OpenaiCacheClearMutationVariables>(
        openaiCacheClearMutation,
    );
    const [value, setValue] = useState<string | number>(100);
    const [selectedPersonality, setSelectedPersonality] = useState<string | null>(null);

    const onClear = async () => {
        const keep = Number(value);
        if (Number.isNaN(keep)) {
            notifications.show({
                title: "Invalid number",
                message: "Please enter a valid number of entries to keep.",
                color: "red",
            });
            return;
        }

        const variables: OpenaiCacheClearMutationVariables = { keep };
        if (selectedPersonality) {
            variables.personality = selectedPersonality;
        }

        const result = await executeClear(variables);

        if (result.error) {
            notifications.show({
                title: "Failed to clear cache",
                message: result.error.message,
                color: "red",
            });
        } else {
            notifications.show({
                title: "Cache cleard",
                message: `${result.data?.openaiCacheClear} items cleard from cache`,
            });
            modals.closeAll();
            refetch({ requestPolicy: "network-only" });
        }
    };

    return (
        <Stack>
            <NumberInput
                value={value}
                onChange={setValue}
                allowNegative={false}
                label="How many cache entries do you want to keep?"
            />
            <Select
                label="Select Personality (optional)"
                placeholder="Choose a personality to clear its cache"
                data={[
                    { value: "", label: "All Personalities" },
                    ...names.map((name) => ({ value: name, label: name })),
                ]}
                value={selectedPersonality ?? ""}
                onChange={(value) => setSelectedPersonality(value || null)}
                clearable
            />
            <Button color="red" onClick={onClear} disabled={result.fetching}>
                {result.fetching ? "Clearing..." : "Clear"}
            </Button>
        </Stack>
    );
}

function ClearCacheButton({ refetch, names }: IProps): JSX.Element {
    const onClick = () =>
        modals.open({
            title: "Delete cached entries",
            children: <ClearCacheModal refetch={refetch} names={names} />,
        });

    return <Button onClick={onClick}>Clear cache</Button>;
}

export default ClearCacheButton;
