import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Button } from "@mantine/core";

import { type TuneThreadCloneMutationVariables, cloneMutation, type TuneThreadCloneMutation } from "./queries";

interface IProps {
    tuneThread: {
        id: number;
    };
}

function CloneThreadButton({ tuneThread }: IProps): JSX.Element {
    const [cloneResult, executeClone] = useMutation<TuneThreadCloneMutation, TuneThreadCloneMutationVariables>(
        cloneMutation,
    );

    const clone = async () => {
        const result = await executeClone({ tuneThreadId: tuneThread.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete thread",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Thread deleted",
                message: "Thread deleted",
            });
        }
    };

    return (
        <Button onClick={clone} disabled={cloneResult.fetching}>
            Clone
        </Button>
    );
}

export default CloneThreadButton;
