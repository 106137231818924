import { Box, Select } from "@mantine/core";
import type { GetInputPropsReturnType } from "@mantine/form/lib/types";
import { useQuery } from "urql";
import Loading from "../Loading";
import { type AiPersonalityListQuery, type AiPersonalityListQueryVariables, aiPersonalityListQuery } from "./queries";

function PersonalityInput(props: GetInputPropsReturnType): JSX.Element {
    const [result] = useQuery<AiPersonalityListQuery, AiPersonalityListQueryVariables>({
        query: aiPersonalityListQuery,
    });

    if (result.fetching) {
        return <Loading text="Loading Ai Personalities..." />;
    }
    if (result.error) {
        return <Box>Error: {result.error.message}</Box>;
    }
    const data = result.data!.aiPersonalityList.map((pers) => ({
        value: pers.id.toString(),
        label: pers.name,
    }));
    return (
        <Box>
            <Select
                label="Ai Personalities to clean transcription"
                placeholder="Select personality"
                searchable
                data={data}
                {...props}
            />
        </Box>
    );
}

export default PersonalityInput;
