import { Box, Image } from "@mantine/core";
import { useState } from "react";

import ImageModal from "./ImageModal";
import classes from "./ImageResults.module.css";
import type { GeneratedImageGenerateMutation } from "./queries";

type IImage = GeneratedImageGenerateMutation["generatedImageGenerate"][0];

interface IProps {
    generatedImages: IImage[];
}

function ImageResults({ generatedImages }: IProps): JSX.Element {
    const [selectedImage, setSelectedImage] = useState<IImage | null>(null);
    const select = (image: IImage) => {
        setSelectedImage(image);
    };
    return (
        <>
            <Box className={classes.grid}>
                {generatedImages.map((img) => (
                    <Box onClick={() => select(img)} key={img.id} style={{ cursor: "pointer" }}>
                        <Image radius="sm" src={img.file?.src} width={400} height={400 / 1.75} fit="contain" />
                    </Box>
                ))}
            </Box>
            <ImageModal image={selectedImage} close={() => setSelectedImage(null)} showDelete={false} />
        </>
    );
}

export default ImageResults;
