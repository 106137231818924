import { Button, Group, Text, Modal, TextInput, LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { type UserChangePasswordMutationVariables, passwordMutation, type UserChangePasswordMutation } from "./queries";
import { useMutation } from "urql";

interface IProps {
    user: {
        id: number;
        firstName: string;
        lastName: string;
    };
}

interface IFormValues {
    password: string;
    repeatPassword: string;
}

function PasswordButton({ user }: IProps): JSX.Element {
    const [opened, { open, close }] = useDisclosure(false);
    const [changeResult, executeChange] = useMutation<UserChangePasswordMutation, UserChangePasswordMutationVariables>(
        passwordMutation,
    );
    const form = useForm<IFormValues>({
        initialValues: {
            password: "",
            repeatPassword: "",
        },
        validate: {
            password: (value) => (value.length < 8 ? "Password must be 8 character" : null),
            repeatPassword: (value, values) => (value !== values.password ? "Passwords do not match" : null),
        },
    });

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeChange({ userId: user.id, password: values.password });
        if (result.error) {
            notifications.show({
                title: "Failed update password",
                message: result.error.message,
                color: "red",
            });
        } else {
            notifications.show({
                title: "User password updated",
                message: result.data?.userChangePassword.password,
            });
            close();
        }
    };

    return (
        <>
            <Button onClick={open}>Change password</Button>
            <Modal opened={opened} onClose={close} title="Change password" centered>
                <LoadingOverlay visible={changeResult.fetching} />
                <Text>
                    Change password for {user.firstName} {user.lastName}
                </Text>
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <TextInput withAsterisk label="Password" type="password" {...form.getInputProps("password")} />
                    <TextInput
                        withAsterisk
                        label="Repeat password"
                        type="password"
                        {...form.getInputProps("repeatPassword")}
                    />
                    <Group>
                        <Button type="submit" disabled={changeResult.fetching}>
                            Save
                        </Button>
                    </Group>
                </form>
            </Modal>
        </>
    );
}

export default PasswordButton;
