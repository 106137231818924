import { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "urql";

import { Box, Title } from "@mantine/core";
import Loading from "../Loading";
import type { Language } from "../utils";
import classes from "./LoadSearch.module.css";
import SearchForm, { type IFormValues } from "./SearchForm";
import SearchResults from "./SearchResults";
import { type DocumentSearchQuery, type DocumentSearchQueryVariables, searchQuery } from "./queries";

export function buildSearchString(values: IFormValues & { page: number }): string {
    const searchParts = [];
    searchParts.push(`page=${values.page}`);
    if (values.name) {
        searchParts.push(`name=${encodeURIComponent(values.name)}`);
    }
    if (values.query) {
        searchParts.push(`query=${encodeURIComponent(values.query)}`);
    }
    if (values.languages.length) {
        searchParts.push(`languages=${encodeURIComponent(values.languages.join(","))}`);
    }
    if (values.tags.length) {
        searchParts.push(`tags=${encodeURIComponent(values.tags.join(","))}`);
    }
    return `?${searchParts.join("&")}`;
}

function isEmpty(values: IFormValues): boolean {
    return (
        values.name.length === 0 &&
        values.query.length === 0 &&
        values.tags.length === 0 &&
        values.languages.length === 0
    );
}

function LoadSearch(): JSX.Element {
    const { search } = useLocation();
    const navigate = useNavigate();
    const initialValues = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const query = searchParams.get("query") ?? "";
        const name = searchParams.get("name") ?? "";
        const tags = (searchParams.get("tags") ?? "").split(",").filter((val) => !!val);
        const languages = (searchParams.get("languages") ?? "").split(",").filter((val) => !!val) as Language[];
        const page = Number.parseInt(searchParams.get("page") ?? "1");
        return { name, query, tags, languages, page };
    }, [search]);
    const searchEmpty = isEmpty(initialValues);
    const [searchResult] = useQuery<DocumentSearchQuery, DocumentSearchQueryVariables>({
        query: searchQuery,
        variables: {
            name: initialValues.name,
            query: initialValues.query,
            page: initialValues.page,
            tags: initialValues.tags.length ? initialValues.tags : [],
            languages: initialValues.languages.length ? initialValues.languages : [],
        },
        pause: searchEmpty,
    });

    const handleSubmit = (values: IFormValues) => {
        navigate({
            pathname: "",
            search: buildSearchString({ ...values, page: 1 }),
        });
    };

    let main = null;
    if (searchResult.fetching) {
        main = <Loading />;
    } else if (searchResult.error) {
        main = <Box>Error: {searchResult.error.message}</Box>;
    } else if (!searchResult.data || searchResult.data.documentSearch.maxPage === 0) {
        main = <Box>No results found</Box>;
    } else {
        main = (
            <SearchResults
                documents={searchResult.data.documentSearch.items}
                searchValues={initialValues!}
                maxPage={searchResult.data.documentSearch.maxPage}
                page={initialValues.page}
            />
        );
    }

    return (
        <div className={classes.grid}>
            <Title>Search Knowledge database</Title>
            <SearchForm fetching={searchResult.fetching} handleSubmit={handleSubmit} initialValues={initialValues} />
            {main}
            <Link to="..">Back</Link>
        </div>
    );
}

export default LoadSearch;
