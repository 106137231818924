import { useEffect } from "react";
import type { UseQueryExecute, UseQueryState } from "urql";

export type DocumentType = "PLAINTEXT" | "PDF" | "WORD" | "POWERPOINT" | "WEBPAGE" | "AUDIO";

export function documentTypeLabel(docuemntType: DocumentType): string {
    switch (docuemntType) {
        case "PLAINTEXT":
            return "Plain text";
        case "PDF":
            return "PDF file";
        case "WORD":
            return "Word file";
        case "POWERPOINT":
            return "PowerPoint file";
        case "WEBPAGE":
            return "Webpage";
        case "AUDIO":
            return "Audio file";
        default:
            return "Unknow";
    }
}

export type ProcessingStatus = "PROCESSING" | "FINISHED" | "FAILED";

export function processingStatusLabel(processingStatus: ProcessingStatus): string {
    switch (processingStatus) {
        case "PROCESSING":
            return "Processing";
        case "FINISHED":
            return "Finished";
        case "FAILED":
            return "Processing failed";
        default:
            return "Unknown";
    }
}

export const DOCUMENT_TYPES: { value: DocumentType; label: string }[] = [
    { value: "PLAINTEXT", label: documentTypeLabel("PLAINTEXT") },
    { value: "PDF", label: documentTypeLabel("PDF") },
    { value: "WORD", label: documentTypeLabel("WORD") },
    { value: "POWERPOINT", label: documentTypeLabel("POWERPOINT") },
    { value: "WEBPAGE", label: documentTypeLabel("WEBPAGE") },
    { value: "AUDIO", label: documentTypeLabel("AUDIO") },
];

export type FileType = "PDF" | "WORD" | "POWERPOINT" | "MP3" | "WAV" | "WEBM" | "MP4" | "QUICKTIME";

export function getFileType(file: File): FileType | null {
    switch (file.type) {
        case "application/pdf":
            return "PDF";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return "WORD";
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return "POWERPOINT";
        case "audio/mpeg":
            return "MP3";
        case "audio/wav":
            return "WAV";
        case "audio/webm":
            return "WEBM";
        case "video/webm":
            return "WEBM";
        case "audio/mp4":
            return "MP4";
        case "audio/m4a":
            return "MP4";
        case "video/mp4":
            return "MP4";
        case "video/quicktime":
            return "QUICKTIME";
        default:
            return null;
    }
}

export function getMime(docuemntType: DocumentType): string[] {
    switch (docuemntType) {
        case "PDF":
            return ["application/pdf"];
        case "WORD":
            return ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        case "POWERPOINT":
            return ["application/vnd.openxmlformats-officedocument.presentationml.presentation"];
        case "AUDIO":
            return [
                "audio/mpeg",
                "audio/wav",
                "audio/webm",
                "audio/mp4",
                "audio/m4a",
                "video/mp4",
                "video/quicktime",
                "video/webm",
            ];
        default:
            return [""];
    }
}

export type ItemRole = "SYSTEM" | "USER" | "ASSISTANT" | "TOOL";

export type Language = "ENGLISH" | "SWEDISH" | "GERMAN" | "FINNISH" | "SPANISH" | "DUTCH";

export function languageLabel(langauge: Language | null): string {
    switch (langauge) {
        case "ENGLISH":
            return "English";
        case "SWEDISH":
            return "Swedish";
        case "GERMAN":
            return "German";
        case "FINNISH":
            return "Finnish";
        default:
            return "";
    }
}

export const LANGUAGES: Language[] = ["ENGLISH", "SWEDISH", "FINNISH", "GERMAN"];

export function useProcessing<T>(
    result: UseQueryState<T>,
    execute: UseQueryExecute,
    isProcessing: (result: UseQueryState<T>) => boolean,
) {
    useEffect(() => {
        const interval = window.setInterval(() => {
            if (!result.fetching && !result.error && result.data) {
                if (isProcessing(result)) {
                    execute({
                        requestPolicy: "cache-and-network",
                    });
                }
            }
        }, 5000);
        return () => {
            window.clearInterval(interval);
        };
    }, [result, execute, isProcessing]);
}
