import { Box, Button, LoadingOverlay, Pagination, Stack, Table, Text } from "@mantine/core";
import { useState } from "react";
import { useQuery } from "urql";
import Loading from "../Loading";
import { languageLabel } from "../utils";
import { type TranscriptionPageQuery, type TranscriptionPageQueryVariables, transcriptionPageQuery } from "./queries";

interface IProps {
    attachTranscription: (transcriptionId: number) => Promise<void>;
    fetching: boolean;
}

function AttachTranscriptionTable({ fetching, attachTranscription }: IProps): JSX.Element {
    const [page, setPage] = useState(1);
    const [result, execute] = useQuery<TranscriptionPageQuery, TranscriptionPageQueryVariables>({
        query: transcriptionPageQuery,
        variables: {
            page,
        },
    });

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.transcriptionPage.maxPage === 0) {
        main = <Box>No transcriptions in database</Box>;
    } else {
        main = (
            <>
                <Pagination total={result.data.transcriptionPage.maxPage} value={page} onChange={setPage} />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>Created</Table.Th>
                            <Table.Th>Langauge</Table.Th>
                            <Table.Th colSpan={3} />
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {result.data.transcriptionPage.items.map((transcription) => (
                            <Table.Tr key={transcription.id}>
                                <Table.Td>{transcription.name}</Table.Td>
                                <Table.Td>{new Date(transcription.created).toLocaleString("en-GB")}</Table.Td>
                                <Table.Td>{languageLabel(transcription.language)}</Table.Td>
                                <Table.Td>
                                    <Button onClick={() => attachTranscription(transcription.id)}>
                                        Add transcript
                                    </Button>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
                <Pagination total={result.data.transcriptionPage.maxPage} value={page} onChange={setPage} />
            </>
        );
    }

    return (
        <Stack maw={1000} mx="auto" my="md">
            <LoadingOverlay visible={fetching} />
            <Text>Here you can attach a transcription to the conversation.</Text>
            {main}
        </Stack>
    );
}

export default AttachTranscriptionTable;
