import { Box, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";

import Loading from "../Loading";
import AiPersonalityForm, { type IFormValues } from "./AiPersonalityForm";
import {
    type AiPersonalityEditMutation,
    type AiPersonalityEditMutationVariables,
    type AiPersonalityQuery,
    type AiPersonalityQueryVariables,
    aiPersonalityQuery,
    editMutation,
} from "./queries";

function Edit(): JSX.Element {
    const { aiPersonalityId } = useParams();
    const navigate = useNavigate();
    const [editResult, executeEdit] = useMutation<AiPersonalityEditMutation, AiPersonalityEditMutationVariables>(
        editMutation,
    );
    const [result] = useQuery<AiPersonalityQuery, AiPersonalityQueryVariables>({
        query: aiPersonalityQuery,
        variables: {
            aiPersonalityId: Number.parseInt(aiPersonalityId!),
        },
    });

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeEdit({
            data: {
                id: Number.parseInt(aiPersonalityId!),
                name: values.name,
                externalName: values.externalName || null,
                systemPrompt: values.systemPrompt,
                temperature: values.temperature,
                includeRetrival: values.includeRetrival,
                searchPrompt: values.includeRetrival ? values.searchPrompt : null,
                tags: values.tags,
                languages: values.languages,
                model: values.model,
                useCache: values.useCache,
                showInChat: values.showInChat,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save AI personality",
                message: result.error.message,
            });
        } else {
            const aiPersonality = result.data!.aiPersonalityEdit;
            notifications.show({
                title: "AI personality saved",
                message: `AI personality ${aiPersonality.name} updated`,
            });
            navigate("..");
        }
    };

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const aiPersonality = result.data?.aiPersonality;
        if (!aiPersonality) {
            main = <Box>AI personality not found</Box>;
        } else {
            const initialValues = {
                name: aiPersonality.name,
                externalName: aiPersonality.externalName || "",
                systemPrompt: aiPersonality.systemPrompt,
                temperature: aiPersonality.temperature,
                includeRetrival: aiPersonality.includeRetrival,
                searchPrompt: aiPersonality.searchPrompt || "",
                tags: aiPersonality.tags,
                languages: aiPersonality.languages,
                model: aiPersonality.model,
                useCache: aiPersonality.useCache,
                showInChat: aiPersonality.showInChat || false,
            };
            main = (
                <AiPersonalityForm
                    handleSave={handleSubmit}
                    initialValues={initialValues}
                    fetching={editResult.fetching}
                />
            );
        }
    }

    return (
        <>
            <Title order={2}>Edit AI personlity</Title>
            {main}
            <Link to="..">Back</Link>
        </>
    );
}

export default Edit;
