import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useMutation } from "urql";

import { Button } from "@mantine/core";
import ReferenceForm, { type IFormValues } from "./ReferenceForm";
import { type TuneThreadEditMutation, type TuneThreadEditMutationVariables, editMutation } from "./queries";

interface IProps {
    tuneThread: {
        id: number;
        positiveReference: string | null;
        negativeReference: string | null;
        llmJudge: string | null;
    };
}

function EditReferenceButton({ tuneThread }: IProps): JSX.Element {
    const [updateResult, executeUpdate] = useMutation<TuneThreadEditMutation, TuneThreadEditMutationVariables>(
        editMutation,
    );

    const edit = async (values: IFormValues) => {
        const result = await executeUpdate({
            data: {
                tuneThreadId: tuneThread.id,
                positiveReference: values.positiveReference || null,
                negativeReference: values.negativeReference || null,
                llmJudge: values.llmJudge || null,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to update reference result",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Thread updated",
                message: "Reference result for thread updated",
            });
        }
    };

    const initialValues: IFormValues = {
        positiveReference: tuneThread.positiveReference || "",
        negativeReference: tuneThread.negativeReference || "",
        llmJudge: tuneThread.llmJudge || "",
    };

    const onClick = () =>
        modals.open({
            title: "Edit reference result",
            size: "xl",
            children: (
                <ReferenceForm
                    fetching={updateResult.fetching}
                    handleSubmit={(values) => {
                        edit(values);
                        modals.closeAll();
                    }}
                    initialValues={initialValues}
                />
            ),
        });

    return (
        <Button onClick={onClick} disabled={updateResult.fetching}>
            Edit reference
        </Button>
    );
}

export default EditReferenceButton;
