import { gql } from "urql";

// External page query

export const externalPageQuery = gql`
    query ExternalPageQuery($page:Int!) {
        externalPage(page:$page) {
            items {
                id
                publicId
                name
                allowDocumentIntelligence
                allowEmbeddings
                allowTranscribe
                externalPersonalities {
                    edges {
                        node {
                            aiPersonality {
                                id
                                publicId
                                name
                            }
                        }
                    }
                }
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/external-page-query";

// Get external query

export const externalQuery = gql`
    query ExternalQuery($externalId:Int!) {
        external(externalId:$externalId) {
            id
            publicId
            name
            allowDocumentIntelligence
            allowEmbeddings
            allowTranscribe
            externalPersonalities {
                edges {
                    node {
                        aiPersonality {
                            id
                            publicId
                            name
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/external-query";

// Add external mutation

export const addMutation = gql`
    mutation ExternalAddMutation($data:ExternalCreateInput!) {
        externalCreate(data:$data) {
            id
            publicId
            name
            allowDocumentIntelligence
            allowEmbeddings
            allowTranscribe
            externalPersonalities {
                edges {
                    node {
                        aiPersonality {
                            id
                            publicId
                            name
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/external-add-mutation";

// List ai personalities query

export const aiPersonalityListQuery = gql`
    query AiPersonalityListQuery {
        aiPersonalityList(inChat:false) {
            id
            publicId
            name
        }
    }
`;

export type * from "./__generated__/ai-personality-list-query";

// Delete External mutation

export const deleteMutation = gql`
    mutation ExternalDeleteMutation($externalId:Int!) {
        externalDelete(externalId:$externalId) {
            id
            publicId
            name
            allowDocumentIntelligence
            allowEmbeddings
            allowTranscribe
            externalPersonalities {
                edges {
                    node {
                        aiPersonality {
                            id
                            publicId
                            name
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/external-delete-mutation";

// Edit External mutation

export const editMutation = gql`
    mutation ExternalEditMutation($data:ExternalEditInput!) {
        externalEdit(data:$data) {
            id
            publicId
            name
            allowDocumentIntelligence
            allowEmbeddings
            allowTranscribe
            externalPersonalities {
                edges {
                    node {
                        aiPersonality {
                            id
                            publicId
                            name
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/external-edit-mutation";
