import { Badge, Box, Button, Group, Pagination, Table, Title, Tooltip } from "@mantine/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "urql";
import Loading from "../Loading";
import { documentTypeLabel, languageLabel, processingStatusLabel, useProcessing } from "../utils";
import DeleteButton from "./DeleteButton";
import classes from "./Documents.module.css";
import ProcessAllButton from "./ProcessAllButton";
import { type DocumentPageQuery, type DocumentPageQueryVariables, documentPageQuery } from "./queries";

function Documents(): JSX.Element {
    const [page, setPage] = useState(1);
    const [result, execute] = useQuery<DocumentPageQuery, DocumentPageQueryVariables>({
        query: documentPageQuery,
        variables: {
            page,
        },
    });

    useProcessing(result, execute, (result) =>
        result.data!.documentPage.items.reduce((acc, items) => {
            return acc || items.processingStatus === "PROCESSING";
        }, false),
    );

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.documentPage.maxPage === 0) {
        main = <Box>No documents in database</Box>;
    } else {
        main = (
            <>
                <Pagination total={result.data.documentPage.maxPage} value={page} onChange={setPage} />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Type</Table.Th>
                            <Table.Th>Status</Table.Th>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>Created</Table.Th>
                            <Table.Th>Tokens</Table.Th>
                            <Table.Th>Langauge</Table.Th>
                            <Table.Th>Tags</Table.Th>
                            <Table.Th colSpan={3} />
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {result.data.documentPage.items.map((document) => (
                            <Table.Tr key={document.id}>
                                <Table.Td>{documentTypeLabel(document.documentType)}</Table.Td>
                                <Table.Td>{processingStatusLabel(document.processingStatus)}</Table.Td>
                                <Table.Td>{document.name}</Table.Td>
                                <Table.Td>{new Date(document.created).toLocaleString("en-GB")}</Table.Td>
                                <Table.Td>{document.tokens}</Table.Td>
                                <Table.Td>{languageLabel(document.language)}</Table.Td>
                                <Table.Td>
                                    <Group gap="xs">
                                        {document.tags.map((tag) => (
                                            <Tooltip key={tag} label={tag}>
                                                <Badge size="xs" classNames={{ root: classes.tag }}>
                                                    {tag}
                                                </Badge>
                                            </Tooltip>
                                        ))}
                                    </Group>
                                </Table.Td>
                                <Table.Td>
                                    <Button component={Link} to={`${document.id}/`}>
                                        Details
                                    </Button>
                                </Table.Td>
                                <Table.Td>
                                    <Button component={Link} to={`${document.id}/edit`}>
                                        Edit
                                    </Button>
                                </Table.Td>
                                <Table.Td>
                                    <DeleteButton document={document} />
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
                <Pagination total={result.data.documentPage.maxPage} value={page} onChange={setPage} />
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>Knowledge database</Title>
            <Group>
                <Button component={Link} to="search">
                    Search database
                </Button>
                <Button component={Link} to="add">
                    Add document
                </Button>
                <ProcessAllButton />
            </Group>
            {main}
        </Box>
    );
}

export default Documents;
