import { useQuery } from "urql";
import { Box, MultiSelect } from "@mantine/core";

import { aiPersonalityListQuery, type AiPersonalityListQuery, type AiPersonalityListQueryVariables } from "./queries";
import type { GetInputPropsReturnType } from "@mantine/form/lib/types";
import Loading from "../Loading";

function PersonalitiesInput(props: GetInputPropsReturnType): JSX.Element {
    const [result] = useQuery<AiPersonalityListQuery, AiPersonalityListQueryVariables>({
        query: aiPersonalityListQuery,
    });

    if (result.fetching) {
        return <Loading text="Loading Ai Personalities..." />;
    }
    if (result.error) {
        return <Box>Error: {result.error.message}</Box>;
    }
    const data = result.data!.aiPersonalityList.map((pers) => ({
        value: pers.publicId,
        label: pers.name,
    }));
    return (
        <Box>
            <MultiSelect
                label="Ai Personalities"
                placeholder="Select personalities"
                searchable
                data={data}
                {...props}
            />
        </Box>
    );
}

export default PersonalitiesInput;
