import { Button, Text } from "@mantine/core";
import { useMutation } from "urql";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";

import {
    processAllMutation,
    type DocumentProcessAllMutation,
    type DocumentProcessAllMutationVariables,
} from "./queries";

function ProcessAllButton(): JSX.Element {
    const [processResult, executeProcess] = useMutation<
        DocumentProcessAllMutation,
        DocumentProcessAllMutationVariables
    >(processAllMutation);

    const processAll = async () => {
        const result = await executeProcess({});
        if (result.error) {
            notifications.show({
                title: "Failed to process documents",
                message: result.error.message,
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Process documents?",
            children: (
                <Text size="sm">Are you sure you want to re-process all the documents in the Knowledge database?</Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: processAll,
        });

    return (
        <Button disabled={processResult.fetching} onClick={onClick}>
            Process all documents
        </Button>
    );
}

export default ProcessAllButton;
