import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useMutation } from "urql";

import { type TuneThreadAddMutation, type TuneThreadAddMutationVariables, addMutation } from "./queries";

interface IProps {
    aiPersonality: {
        id: number;
        name: string;
    };
}

function AddThreadButton({ aiPersonality }: IProps): JSX.Element {
    const [addResult, executeAdd] = useMutation<TuneThreadAddMutation, TuneThreadAddMutationVariables>(addMutation);

    const add = async () => {
        const result = await executeAdd({
            data: [{ aiPersonalityId: aiPersonality.id, items: [], positiveReference: null, negativeReference: null }],
        });
        if (result.error) {
            notifications.show({
                title: "Failed to add thread",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Thread added",
                message: `Thread added to ${aiPersonality.name} for tuning`,
            });
        }
    };

    return (
        <Button onClick={add} disabled={addResult.fetching}>
            Add thread
        </Button>
    );
}

export default AddThreadButton;
