import { v4 as uuidv4 } from "uuid";

interface UploadOptions {
    file: File;
    chunkSize?: number;
    onProgress?: (progress: number) => void;
}

type IResponse =
    | {
          status: "error";
          error: string;
      }
    | {
          status: "success";
      }
    | {
          status: "complete";
          name: string;
      };

export async function chunkedUpload({
    file,
    chunkSize = 1024 * 1024 * 5, // Default 5MB chunk size
    onProgress,
}: UploadOptions): Promise<string> {
    const totalChunks = Math.ceil(file.size / chunkSize);
    const uploadId = uuidv4();

    for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("uploadId", uploadId);
        formData.append("chunkNumber", (i + 1).toString());
        formData.append("totalChunks", totalChunks.toString());
        formData.append("fileName", file.name);
        formData.append("fileSize", file.size.toString());

        try {
            const response = await fetch("/upload", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: IResponse = await response.json();

            if (data.status === "error") {
                throw new Error(data.error);
            }

            if (data.status === "complete") {
                onProgress?.(100);
                console.log("File successfully uploaded");
                return data.name;
            }

            const newProgress = ((i + 1) / totalChunks) * 100;
            onProgress?.(newProgress);
            console.log(`Chunk ${i + 1} uploaded.`);
        } catch (error) {
            console.error(`Error uploading chunk ${i + 1}:`, error);
            throw error;
        }
    }

    throw new Error("Upload completed without receiving completion confirmation");
}
