import { ActionIcon, Box, Divider } from "@mantine/core";
import { useClipboard, useDisclosure, useHover } from "@mantine/hooks";
import {
    IconArrowBarDown,
    IconArrowBarToUp,
    IconCopy,
    IconReload,
    IconRobotFace,
    IconSearch,
    IconUser,
} from "@tabler/icons-react";
import "@mantine/code-highlight/styles.css";
import ActionButton from "../ActionButton";
import ChatMarkdown from "../ChatMarkdown";
import RetrievalContext from "../RetrievalContext";
import SearchTool from "../SearchTool";
import type { ItemRole } from "../utils";
import classes from "./ThreadItem.module.css";

interface IThreadItem {
    id: number | null;
    role: ItemRole;
    content: string;
    toolName: string | null;
    toolArguments: string | null;
    context: boolean;
    threadItemRetrievalContext: {
        id: number;
        query: string;
        context: string;
        hydeQuery: string;
        avgRelevance: number;
        documentNames: string[];
    } | null;
}

interface IProps {
    item: IThreadItem;
    reAskAi: (item: IThreadItem) => void;
}

const ICON_SIZE = 32;

function ThreadItem({ item, reAskAi }: IProps): JSX.Element | null {
    const { hovered, ref } = useHover();
    const [opened, { open, close }] = useDisclosure(!item.context);
    const clipboard = useClipboard();

    const copy = () => {
        clipboard.copy(item.content);
    };

    const showButtons = (
        <>
            {!opened && <ActionButton onClick={open} label="Show content" icon={<IconArrowBarDown stroke={1} />} />}
            {opened && <ActionButton onClick={close} label="Hide content" icon={<IconArrowBarToUp stroke={1} />} />}
        </>
    );

    const body =
        item.role === "ASSISTANT" && item.toolName === "documentSearch" ? (
            <SearchTool item={item} />
        ) : item.role === "ASSISTANT" && !item.context ? (
            <ChatMarkdown content={item.content} />
        ) : (
            <Box>
                <Box className={classes.userInput}>{item.content}</Box>
                {item.threadItemRetrievalContext && (
                    <Box mb="lg">
                        <Divider color="gray" label="Retrival metadata" labelPosition="center" />
                        <RetrievalContext context={item.threadItemRetrievalContext} />
                        <Divider color="gray" label="Retrival result" labelPosition="center" />
                    </Box>
                )}
            </Box>
        );

    switch (item.role) {
        case "USER":
            return (
                <Box className={classes.chatBox} ref={ref}>
                    {hovered && (
                        <ActionIcon.Group className={classes.icons}>
                            {item.context && showButtons}
                            {item.id != null && (
                                <ActionButton
                                    onClick={() => reAskAi(item)}
                                    label="Ask AI again"
                                    icon={<IconReload stroke={1} />}
                                />
                            )}
                            <ActionButton onClick={copy} label="Copy content" icon={<IconCopy stroke={1} />} />
                        </ActionIcon.Group>
                    )}
                    <Box>
                        <IconUser size={ICON_SIZE} />
                    </Box>
                    {opened ? body : <Box>CONTEXT</Box>}
                </Box>
            );
        case "ASSISTANT":
            return (
                <Box className={classes.chatBox} ref={ref}>
                    {hovered && (
                        <ActionIcon.Group className={classes.icons}>
                            {item.context && showButtons}
                            <ActionButton onClick={copy} label="Copy content" icon={<IconCopy stroke={1} />} />
                        </ActionIcon.Group>
                    )}
                    <Box>
                        <IconRobotFace size={ICON_SIZE} />
                    </Box>
                    {opened ? body : <Box>CONTEXT</Box>}
                </Box>
            );
        case "TOOL":
            return (
                <Box className={classes.chatBox} ref={ref}>
                    {hovered && (
                        <ActionIcon.Group className={classes.icons}>
                            {item.context && showButtons}
                            <ActionButton onClick={copy} label="Copy content" icon={<IconCopy stroke={1} />} />
                        </ActionIcon.Group>
                    )}
                    <Box>
                        <IconSearch size={ICON_SIZE} />
                    </Box>
                    {opened ? (
                        body
                    ) : (
                        <Box>SEARCH RESULT {item.threadItemRetrievalContext?.avgRelevance?.toPrecision(4)}</Box>
                    )}
                </Box>
            );
        default:
            return null;
    }
}

export default ThreadItem;
