import { Button, Group, LoadingOverlay, Select, Stack, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";

export interface IFormValues {
    positiveReference: string;
    negativeReference: string;
    llmJudge: string;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    initialValues?: IFormValues;
    fetching: boolean;
}

function ReferenceForm({ handleSubmit, initialValues, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: initialValues ?? {
            positiveReference: "",
            negativeReference: "",
            llmJudge: "",
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto">
                <LoadingOverlay visible={fetching} />
                <Textarea
                    withAsterisk
                    autosize
                    minRows={3}
                    label="Positive reference result"
                    {...form.getInputProps("positiveReference")}
                />
                <Textarea
                    withAsterisk
                    autosize
                    minRows={3}
                    label="Negative reference result"
                    {...form.getInputProps("negativeReference")}
                />
                <Textarea
                    withAsterisk
                    autosize
                    minRows={3}
                    label="LLM Judge system prompt"
                    {...form.getInputProps("llmJudge")}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={fetching}>
                        Save
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default ReferenceForm;
