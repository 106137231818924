import { Box, Title, type TitleProps } from "@mantine/core";
import type { ReactNode } from "react";

import classes from "./InfoNode.module.css";

interface IProps {
    title: string;
    order?: TitleProps["order"];
    children: ReactNode;
}

function InfoNode({ title, order = 3, children }: IProps) {
    return (
        <Box className={classes.infoNode}>
            <Title order={order} className={classes.nodeTitle}>
                {title}
            </Title>
            {children}
        </Box>
    );
}

export default InfoNode;
