import { Box, Button, Group, Pagination, Table, Title } from "@mantine/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "urql";

import Loading from "../Loading";
import { languageLabel, processingStatusLabel, useProcessing } from "../utils";
import DeleteButton from "./DeleteButton";
import classes from "./Transcriptions.module.css";
import { type TranscriptionPageQuery, type TranscriptionPageQueryVariables, transcriptionPageQuery } from "./queries";

function Transcriptions(): JSX.Element {
    const [page, setPage] = useState(1);
    const [result, execute] = useQuery<TranscriptionPageQuery, TranscriptionPageQueryVariables>({
        query: transcriptionPageQuery,
        variables: {
            page,
        },
    });

    useProcessing(result, execute, (result) =>
        result.data!.transcriptionPage.items.reduce((acc, items) => {
            return acc || items.processingStatus === "PROCESSING";
        }, false),
    );

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.transcriptionPage.maxPage === 0) {
        main = <Box>No transcriptions have been created</Box>;
    } else {
        main = (
            <>
                <Pagination total={result.data.transcriptionPage.maxPage} value={page} onChange={setPage} />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Status</Table.Th>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>Created</Table.Th>
                            <Table.Th>Langauge</Table.Th>
                            <Table.Th colSpan={2} />
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {result.data.transcriptionPage.items.map((transcription) => (
                            <Table.Tr key={transcription.id}>
                                <Table.Td>{processingStatusLabel(transcription.processingStatus)}</Table.Td>
                                <Table.Td>{transcription.name}</Table.Td>
                                <Table.Td>{new Date(transcription.created).toLocaleString("en-GB")}</Table.Td>
                                <Table.Td>{languageLabel(transcription.language)}</Table.Td>
                                <Table.Td>
                                    <Button component={Link} to={`${transcription.id}/`}>
                                        Details
                                    </Button>
                                </Table.Td>
                                <Table.Td>
                                    <DeleteButton transcription={transcription} />
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
                <Pagination total={result.data.transcriptionPage.maxPage} value={page} onChange={setPage} />
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>Transcribe audio</Title>
            <Group>
                <Button component={Link} to="add">
                    Create new
                </Button>
            </Group>
            {main}
        </Box>
    );
}

export default Transcriptions;
