import { Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "urql";

import AiPersonalityForm, { type IFormValues } from "./AiPersonalityForm";
import { type AiPersonalityAddMutation, type AiPersonalityAddMutationVariables, addMutation } from "./queries";

function Add(): JSX.Element {
    const navigate = useNavigate();
    const [addResult, executeAdd] = useMutation<AiPersonalityAddMutation, AiPersonalityAddMutationVariables>(
        addMutation,
    );

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeAdd({
            data: {
                name: values.name,
                externalName: values.externalName || null,
                systemPrompt: values.systemPrompt,
                temperature: values.temperature,
                includeRetrival: values.includeRetrival,
                searchPrompt: values.includeRetrival ? values.searchPrompt : null,
                tags: values.tags,
                languages: values.languages,
                model: values.model,
                useCache: values.useCache,
                showInChat: values.showInChat,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save AI personality",
                message: result.error.message,
                color: "red",
            });
        } else {
            const aiPersonality = result.data?.aiPersonalityCreate;
            notifications.show({
                title: "AI personality saved",
                message: `Saved AI personality ${aiPersonality!.name}`,
            });
            navigate("..");
        }
    };

    return (
        <>
            <Title order={2}>Add AI personality</Title>
            <AiPersonalityForm handleSave={handleSubmit} fetching={addResult.fetching} />
            <Link to="..">Back</Link>
        </>
    );
}

export default Add;
