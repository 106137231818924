import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

import { type ThreadDeleteMutationVariables, deleteMutation, type ThreadDeleteMutation } from "./queries";

interface IProps {
    thread: {
        id: number;
        threadName: string | null;
    };
}

function DeleteButton({ thread }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<ThreadDeleteMutation, ThreadDeleteMutationVariables>(
        deleteMutation,
    );

    const doDelete = async () => {
        const result = await executeDelete({ threadId: thread.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete conversation",
                message: result.error.message,
            });
        } else {
            const thread = result.data!.threadDelete;
            notifications.show({
                title: "Conversation deleted",
                message: `The conversation "${thread.threadName ?? "New chat"}" with the AI has been deleted deleted`,
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete conversations?",
            children: (
                <Text size="sm">
                    Are you sure you want to delete the conversation "{thread.threadName ?? "New chat"}" with the AI?
                </Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <Button onClick={onClick} disabled={deleteResult.fetching} color="red">
            Delete
        </Button>
    );
}

export default DeleteButton;
