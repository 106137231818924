import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Box, Stack, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import { useState } from "react";

import {
    generateImageMutation,
    type GeneratedImageGenerateMutation,
    type GeneratedImageGenerateMutationVariables,
} from "./queries";
import PromptInput from "./PromptInput";
import ImageResults from "./ImageResults";
import Loading from "../Loading";

type IImage = GeneratedImageGenerateMutation["generatedImageGenerate"][0];

function GenerateImages(): JSX.Element {
    const [generateResult, executeGenerate] = useMutation<
        GeneratedImageGenerateMutation,
        GeneratedImageGenerateMutationVariables
    >(generateImageMutation);
    const [generatedImages, setGeneratedImages] = useState<IImage[]>([]);

    const generate = async (prompt: string) => {
        const result = await executeGenerate({ prompt });
        if (result.error) {
            notifications.show({
                title: "AI error",
                message: result.error.message,
            });
        }
        if (result.data) {
            setGeneratedImages(result.data!.generatedImageGenerate);
        }
    };

    return (
        <>
            <Stack maw={800} mx="auto">
                <Title order={2}>{"Ask AI to generate images"}</Title>
                <PromptInput generate={generate} />
                {generateResult.fetching ? (
                    <Loading text="AI is thinking hard..." />
                ) : (
                    <ImageResults generatedImages={generatedImages} />
                )}
                <Box>
                    <Link to="previous">Previously generated images</Link>
                </Box>
            </Stack>
        </>
    );
}

export default GenerateImages;
