import { gql } from "urql";

// Generate new images mutation

export const generateImageMutation = gql`
    mutation GeneratedImageGenerateMutation($prompt:String!) {
        generatedImageGenerate(prompt:$prompt) {
            id
            prompt
            revisedPrompt
            file {
                id
                src
                fileName
            }
        }
    }
`;

export type * from "./__generated__/generated-image-generate-mutation";

// Generated images page query

export const generatedImagePage = gql`
    query GeneratedImagePageQuery($page:Int!) {
        generatedImagePage(page:$page) {
            items {
                id
                prompt
                revisedPrompt
                file {
                    id
                    src
                    fileName
                }
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/generated-image-page-query";

// Delete image mutation

export const deleteImageMutation = gql`
    mutation GeneratedImageDeleteMutation($generatedImageId:Int!) {
        generatedImageDelete(generatedImageId:$generatedImageId) {
            id
            prompt
            file {
                id
                fileName
            }
        }
    }
`;

export type * from "./__generated__/generated-image-delete-mutation";
