import { IconPhotoDown } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import ActionButton from "../ActionButton";

interface IProps {
    image: {
        file: {
            src: string;
            fileName: string;
        } | null;
    };
}

function DownloadButton({ image }: IProps): JSX.Element {
    const download = async () => {
        try {
            const downloadLink = document.createElement("a");
            downloadLink.href = image.file!.src;
            downloadLink.download = image.file!.fileName;
            downloadLink.click();
        } catch (error) {
            console.error("Error downloading image: ", error);
            notifications.show({
                title: "Download error",
                message: "Failed to download image",
            });
        }
    };
    return <ActionButton onClick={download} label="Download image" icon={<IconPhotoDown stroke={1} />} size="lg" />;
}

export default DownloadButton;
