import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

interface IProps {
    source: string;
    dest: string;
}

function DiffModal({ source, dest }: IProps) {
    return (
        <ReactDiffViewer
            oldValue={source}
            newValue={dest}
            leftTitle="Transcribed text"
            rightTitle="Cleaned text"
            splitView={true}
            hideLineNumbers
            compareMethod={DiffMethod.WORDS}
            showDiffOnly={false}
        />
    );
}

export default DiffModal;
