import { Title } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";

import ExternalForm, { type IFormValues } from "./ExternalForm";
import { type ExternalAddMutationVariables, addMutation, type ExternalAddMutation } from "./queries";

function Add(): JSX.Element {
    const navigate = useNavigate();
    const [addResult, executeAdd] = useMutation<ExternalAddMutation, ExternalAddMutationVariables>(addMutation);

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeAdd({
            data: {
                name: values.name,
                allowDocumentIntelligence: values.allowDocumentIntelligence,
                allowEmbeddings: values.allowEmbeddings,
                allowTranscribe: values.allowTranscribe,
                aiPersonalities: values.aiPersonalities,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to create external access rights",
                message: result.error.message,
                color: "red",
            });
        } else {
            notifications.show({
                title: "External access right created",
                message: `Acesess right public ID ${result.data?.externalCreate.publicId}`,
            });
            navigate("..");
        }
    };

    return (
        <>
            <Title order={2}>Add external access right</Title>
            <ExternalForm handleSubmit={handleSubmit} fetching={addResult.fetching} />
            <Link to="..">Back</Link>
        </>
    );
}

export default Add;
