import { Box, Title } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { notifications } from "@mantine/notifications";

import ExternalForm, { type IFormValues } from "./ExternalForm";
import {
    type ExternalEditMutationVariables,
    editMutation,
    type ExternalEditMutation,
    type ExternalQueryVariables,
    externalQuery,
    type ExternalQuery,
} from "./queries";
import Loading from "../Loading";

function Edit(): JSX.Element {
    const { externalId } = useParams();
    const navigate = useNavigate();
    const [editResult, executeEdit] = useMutation<ExternalEditMutation, ExternalEditMutationVariables>(editMutation);
    const [result] = useQuery<ExternalQuery, ExternalQueryVariables>({
        query: externalQuery,
        variables: {
            externalId: Number.parseInt(externalId!),
        },
    });

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeEdit({
            data: {
                id: Number.parseInt(externalId!),
                name: values.name,
                allowDocumentIntelligence: values.allowDocumentIntelligence,
                allowEmbeddings: values.allowEmbeddings,
                allowTranscribe: values.allowTranscribe,
                aiPersonalities: values.aiPersonalities,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save External access rights",
                message: result.error.message,
            });
        } else {
            const external = result.data!.externalEdit;
            notifications.show({
                title: "External saved",
                message: `External access right ${external.name} updated`,
            });
            navigate("..");
        }
    };

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const external = result.data?.external;
        if (!external) {
            main = <Box>External not found</Box>;
        } else {
            const initialValues = {
                name: external.name,
                allowDocumentIntelligence: external.allowDocumentIntelligence,
                allowEmbeddings: external.allowEmbeddings,
                allowTranscribe: external.allowTranscribe,
                aiPersonalities:
                    external.externalPersonalities.edges?.map((edge) => edge!.node!.aiPersonality.publicId) || [],
            };
            main = (
                <ExternalForm
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    fetching={editResult.fetching}
                />
            );
        }
    }

    return (
        <>
            <Title order={2}>Edit external</Title>
            {main}
            <Link to="..">Back</Link>
        </>
    );
}

export default Edit;
