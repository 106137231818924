import { Route, Routes } from "react-router-dom";

import Users from "./Users";
import Add from "./Add";
import Edit from "./Edit";

function UserRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/add" element={<Add />} />
            <Route path="/:userId/edit" element={<Edit />} />
            <Route path="/" element={<Users />} />
        </Routes>
    );
}

export default UserRoutes;
