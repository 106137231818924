import { ActionIcon, Box, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowBarDown, IconArrowBarToUp, IconRobotFace, IconSearch, IconUser } from "@tabler/icons-react";
import ActionButton from "../../ActionButton";
import ChatMarkdown from "../../ChatMarkdown";
import RetrievalContext from "../../RetrievalContext";
import SearchTool from "../../SearchTool";
import DeleteItemButton from "./DeleteItemButton";
import EditItemButton from "./EditItemButton";
import classes from "./Item.module.css";
import ReorderItemsButtons from "./ReorderItemsButtons";
import type { AiPersonalityQuery } from "./queries";

type ITuneThread = Exclude<AiPersonalityQuery["aiPersonality"], null>["tuneThreads"]["edges"][0]["node"];

interface IProps {
    item: {
        id: number;
        role: "SYSTEM" | "USER" | "ASSISTANT" | "TOOL";
        toolCallId: string | null;
        toolName: string | null;
        toolArguments: string | null;
        content: string;
        context: boolean;
        retrievalContext?: {
            query: string;
            context: string;
            hydeQuery: string;
            chunks: string[];
            avgRelevance: number;
            documentNames: string[];
        } | null;
    };
    tuneThread: ITuneThread;
}

export const ICON_SIZE = 32;

function Item({ item, tuneThread }: IProps) {
    const [opened, { open, close }] = useDisclosure(!item.context);

    const sharedButton = (
        <>
            {!!item.id && <DeleteItemButton tuneThreadId={tuneThread.id} tuneThreadItemId={item.id} />}
            {!!item.id && <EditItemButton tuneThreadItem={item} />}
            {!!item.id && (
                <ReorderItemsButtons
                    tuneThread={tuneThread}
                    tuneThreadItemId={item.id}
                    tuneThreadItems={tuneThread.tuneThreadItems.edges.map(({ node }) => node)}
                />
            )}
        </>
    );

    const showButtons = (
        <>
            {!opened && <ActionButton onClick={open} label="Show content" icon={<IconArrowBarDown stroke={1} />} />}
            {opened && <ActionButton onClick={close} label="Hide content" icon={<IconArrowBarToUp stroke={1} />} />}
        </>
    );

    const body =
        item.role === "ASSISTANT" && item.toolName === "documentSearch" ? (
            <SearchTool item={item} />
        ) : item.role === "ASSISTANT" && !item.context ? (
            <ChatMarkdown content={item.content} />
        ) : (
            <Box>
                <Box className={classes.userInput}>{item.content}</Box>
                {item.retrievalContext && (
                    <Box mb="lg">
                        <RetrievalContext context={item.retrievalContext} />
                        <Divider color="gray" label="Retrival result" labelPosition="center" />
                    </Box>
                )}
            </Box>
        );

    switch (item.role) {
        case "USER":
            return (
                <Box className={classes.chatBox}>
                    <ActionIcon.Group className={classes.icons}>
                        {item.context && showButtons}
                        {sharedButton}
                    </ActionIcon.Group>
                    <Box>
                        <IconUser size={ICON_SIZE} />
                    </Box>
                    {opened ? body : <Box>CONTEXT</Box>}
                </Box>
            );
        case "ASSISTANT":
            return (
                <Box className={classes.chatBox}>
                    <ActionIcon.Group className={classes.icons}>
                        {item.context && showButtons}
                        {sharedButton}
                    </ActionIcon.Group>
                    <Box>
                        <IconRobotFace size={ICON_SIZE} />
                    </Box>
                    {opened ? body : <Box>CONTEXT</Box>}
                </Box>
            );
        case "TOOL":
            return (
                <Box className={classes.chatBox}>
                    <ActionIcon.Group className={classes.icons}>
                        {item.context && showButtons}
                        {sharedButton}
                    </ActionIcon.Group>
                    <Box>
                        <IconSearch size={ICON_SIZE} />
                    </Box>
                    {opened ? body : <Box>SEARCH RESULT {item.retrievalContext?.avgRelevance?.toPrecision(4)}</Box>}
                </Box>
            );
        default:
            return null;
    }
}

export default Item;
