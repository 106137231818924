import { Image, Modal, ActionIcon, Stack, Text } from "@mantine/core";

import DeleteButton from "./DeleteButton";
import CopyButton from "./CopyButton";
import DownloadButton from "./DownloadButton";

interface IProps {
    image: {
        id: number;
        prompt: string;
        revisedPrompt: string | null;
        file: {
            src: string;
            fileName: string;
        } | null;
    } | null;
    close: VoidFunction;
    showDelete: boolean;
}

function ImageModal({ image, close, showDelete }: IProps): JSX.Element {
    const title = (
        <Text>
            <strong>Prompt:</strong> {image?.prompt}
        </Text>
    );
    return (
        <Modal opened={image !== null} onClose={close} centered size="calc(100vw - 1rem)" title={title}>
            {image !== null && (
                <Stack>
                    <ActionIcon.Group>
                        <CopyButton image={image} />
                        <DownloadButton image={image} />
                        {showDelete && <DeleteButton image={image} />}
                    </ActionIcon.Group>
                    <Image src={image!.file!.src} fit="contain" />
                    {image.revisedPrompt && (
                        <Text>
                            <strong>Revised prompt:</strong> {image.revisedPrompt}
                        </Text>
                    )}
                </Stack>
            )}
        </Modal>
    );
}

export default ImageModal;
