import { List, Text, Title } from "@mantine/core";
import type { JSX } from "react";

interface IProps {
    context: {
        query: string;
        context: string;
        hydeQuery: string;
        avgRelevance: number;
        documentNames: string[];
    };
}

let docKey = 0;

function RetrievalContext({ context }: IProps): JSX.Element {
    return (
        <>
            <Title order={5}>Query</Title>
            <Text>{context.query}</Text>
            <Title order={5}>Context</Title>
            <Text>{context.context}</Text>
            <Title order={5}>HyDE Query</Title>
            <Text>{context.hydeQuery}</Text>
            <Title order={5}>Average relevance</Title>
            <Text>{context.avgRelevance}</Text>
            <Title order={5}>Documents</Title>
            <List>
                {context.documentNames.map((doc) => (
                    <List.Item key={docKey++}>{doc}</List.Item>
                ))}
            </List>
        </>
    );
}

export default RetrievalContext;
