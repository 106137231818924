import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";

import { type DocumentDeleteMutationVariables, deleteMutation, type DocumentDeleteMutation } from "./queries";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

interface IProps {
    document: {
        id: number;
        name: string;
    };
}

function DeleteButton({ document }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<DocumentDeleteMutation, DocumentDeleteMutationVariables>(
        deleteMutation,
    );

    const doDelete = async () => {
        const result = await executeDelete({ documentId: document.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete Document",
                message: result.error.message,
            });
        } else {
            const document = result.data!.documentDelete;
            notifications.show({
                title: "Document deleted",
                message: `Document ${document.name} deleted`,
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete Document?",
            children: <Text size="sm">Are you sure you want to delete Document {document.name}?</Text>,
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <Button onClick={onClick} disabled={deleteResult.fetching} color="red">
            Delete
        </Button>
    );
}

export default DeleteButton;
