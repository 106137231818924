import { Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { chunkedUpload } from "../chunkedUploader";
import { getFileType } from "../utils";
import DocumentForm, { type IFormValues } from "./DocumentForm";
import { type DocumentAddMutation, type DocumentAddMutationVariables, addMutation } from "./queries";

function Add(): JSX.Element {
    const navigate = useNavigate();
    const [uploading, setUploading] = useState(false);
    const [addResult, executeAdd] = useMutation<DocumentAddMutation, DocumentAddMutationVariables>(addMutation);

    const handleSubmit = async (values: IFormValues) => {
        let storedName = "";
        if (values.file) {
            try {
                setUploading(true);
                storedName = await chunkedUpload({ file: values.file! });
            } catch (error) {
                let message: string;
                if (error instanceof Error) {
                    message = error.message;
                } else {
                    message = "Unknown error";
                }
                notifications.show({
                    title: "Failed to upload file",
                    message,
                    color: "red",
                });
                return;
            } finally {
                setUploading(false);
            }
        }

        const result = await executeAdd({
            data: {
                documentType: values.documentType,
                name: values.name,
                content: values.content || null,
                url: values.url || null,
                fileData: values.file
                    ? { fileType: getFileType(values.file)!, fileName: values.file.name, file: storedName }
                    : null,
                tags: values.tags,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save Document",
                message: result.error.message,
                color: "red",
            });
        } else {
            const document = result.data?.documentCreate;
            notifications.show({
                title: "Document saved",
                message: `Saved Document: ${document!.name}`,
            });
            navigate("..");
        }
    };

    return (
        <>
            <Title order={2}>Add Document to Knowledge database</Title>
            <DocumentForm handleSubmit={handleSubmit} edit={false} fetching={addResult.fetching || uploading} />
            <Link to="..">Back</Link>
        </>
    );
}

export default Add;
