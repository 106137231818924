import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "urql";

import { Box, Button, Title } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { useSiteConfig } from "../SiteConfig";
import classes from "./SelectPersonality.module.css";
import {
    type AiPersonalityListQuery,
    type ThreadCreateMutation,
    type ThreadCreateMutationVariables,
    createMutation,
    personalitiesQuery,
} from "./queries";

type IAiPersonality = AiPersonalityListQuery["aiPersonalityList"][0];

const BLANK_PERSONALITY = {
    name: "Blank personality",
    externalName: null,
    systemPrompt: "You are a helpful assistant",
    temperature: 0.5,
    includeRetrival: false,
    searchPrompt: "",
    tags: [],
    languages: [],
    model: "",
    useCache: false,
    showInChat: null,
};

function SelectPersonality(): JSX.Element {
    const navigate = useNavigate();
    const [result] = useQuery<AiPersonalityListQuery>({
        query: personalitiesQuery,
    });
    const [createResult, executeCreate] = useMutation<ThreadCreateMutation, ThreadCreateMutationVariables>(
        createMutation,
    );
    const config = useSiteConfig();

    const onSelect = async (aiPersonality: Omit<IAiPersonality, "id">) => {
        const result = await executeCreate({
            data: {
                threadName: null,
                personalityData: {
                    name: aiPersonality.name,
                    externalName: aiPersonality.externalName,
                    temperature: aiPersonality.temperature,
                    systemPrompt: aiPersonality.systemPrompt,
                    includeRetrival: aiPersonality.includeRetrival,
                    searchPrompt: aiPersonality.searchPrompt,
                    tags: aiPersonality.tags,
                    languages: aiPersonality.languages,
                    model: aiPersonality.model,
                    useCache: aiPersonality.useCache,
                    showInChat: null,
                },
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to create new Thread",
                message: result.error.message,
                color: "red",
            });
        } else {
            const thread = result.data?.threadCreate!;
            navigate(`../${thread.id}`);
        }
    };

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const aiPersonalities = result.data?.aiPersonalityList ?? [];
        main = (
            <>
                {aiPersonalities.map((aiPersonality) => (
                    <Box key={aiPersonality.id} className={classes.box}>
                        <Title order={3} size="sm" ta="center">
                            {aiPersonality.name}
                        </Title>
                        <Button onClick={() => onSelect(aiPersonality)}>Select</Button>
                    </Box>
                ))}
                <Box className={classes.box}>
                    <Title order={3} size="sm" ta="center">
                        Blank
                    </Title>
                    <Button
                        onClick={() =>
                            onSelect({
                                ...BLANK_PERSONALITY,
                                model: config.chatModels[0],
                            })
                        }
                    >
                        Select
                    </Button>
                </Box>
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>Select AI personality</Title>
            <div className={classes.personalityGrid}>{main}</div>
            <Box>
                <Button component={Link} to="../previous">
                    View previous conversations
                </Button>
            </Box>
        </Box>
    );
}

export default SelectPersonality;
