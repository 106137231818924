import { Route, Routes } from "react-router-dom";

import Documents from "./Documents";
import Document from "./Document";
import Add from "./Add";
import Edit from "./Edit";
import LoadSearch from "./LoadSearch";

function DocumentRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/add" element={<Add />} />
            <Route path="/search" element={<LoadSearch />} />
            <Route path="/:documentId" element={<Document />} />
            <Route path="/:documentId/edit" element={<Edit />} />
            <Route path="/" element={<Documents />} />
        </Routes>
    );
}

export default DocumentRoutes;
