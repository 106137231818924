import { Group, Stack, Text, Title } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { getEncoding } from "js-tiktoken";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { type UseMutationState, useMutation } from "urql";
import { useImmer } from "use-immer";
import { useUserCurrent } from "../CurrentUser";
import Loading from "../Loading";
import { chunkedUpload } from "../chunkedUploader";
import { type ItemRole, getFileType } from "../utils";
import type { IFormValues as IAttachFileFormValues } from "./AttachFileForm";
import AttachModal from "./AttachModal";
import type { IFormValues as IAttachWebpageFormValues } from "./AttachWebpageForm";
import ChatInput from "./ChatInput";
import ForkButton from "./ForkButton";
import Personality from "./Personality";
import ThreadItem from "./ThreadItem";
import {
    type ThreadAskAiMutation,
    type ThreadAskAiMutationVariables,
    type ThreadAttachFileMutation,
    type ThreadAttachFileMutationVariables,
    type ThreadAttachKnowledgeMutation,
    type ThreadAttachKnowledgeMutationVariables,
    type ThreadAttachTranscriptionMutation,
    type ThreadAttachTranscriptionMutationVariables,
    type ThreadAttachWebpageMutation,
    type ThreadAttachWebpageMutationVariables,
    type ThreadQuery,
    type ThreadReAskAiMutation,
    type ThreadReAskAiMutationVariables,
    askAiMutation,
    attachFileMutation,
    attachKnowledgeMutation,
    attachTranscriptionMutation,
    attachWebpageMutation,
    reAskAiMutation,
} from "./queries";

interface IProps {
    thread: NonNullable<ThreadQuery["thread"]>;
}

interface IThreadItem {
    id: number | null;
    role: ItemRole;
    content: string;
    toolName: string | null;
    toolArguments: string | null;
    context: boolean;
    threadItemRetrievalContext: {
        id: number;
        query: string;
        context: string;
        hydeQuery: string;
        avgRelevance: number;
        documentNames: string[];
    } | null;
}

type IPersonality = NonNullable<IProps["thread"]["aiPersonality"]>;

const encoding = getEncoding("cl100k_base");

function useTokenCount(personality: IPersonality, threadItems: IThreadItem[]): number {
    const threadString = useMemo(() => {
        return `${personality.systemPrompt}\n\n${threadItems.map((item) => item.content).join("\n\n")}`;
    }, [personality.systemPrompt, threadItems]);
    const [debouncedString] = useDebouncedValue(threadString, 300);
    const count = useMemo(() => {
        return encoding.encode(debouncedString).length;
    }, [debouncedString]);
    return count;
}

function getLoadingString(
    uploading: boolean,
    askAiResult: UseMutationState,
    reAskAiResult: UseMutationState,
    attachFileResult: UseMutationState,
    attachKnowledgeResult: UseMutationState,
    attachWebpageResult: UseMutationState,
    attachTranscriptionResult: UseMutationState,
): string {
    if (uploading) {
        return "Uploading file...";
    }
    if (askAiResult.fetching) {
        return "AI is thinking hard...";
    }
    if (reAskAiResult.fetching) {
        return "AI is thinking hard...";
    }
    if (attachFileResult.fetching) {
        return "Processing document...";
    }
    if (attachKnowledgeResult.fetching) {
        return "Processing document...";
    }
    if (attachTranscriptionResult.fetching) {
        return "Processing transcript...";
    }
    if (attachWebpageResult.fetching) {
        return "Fetching website...";
    }
    return "";
}

let itemKey = 0;

function Thread({ thread }: IProps): JSX.Element {
    const [threadName, setThreadName] = useState(thread.threadName);
    const [personality, setPersonalty] = useState(thread.aiPersonality!);
    const [uploading, setUploading] = useState(false);
    const [threadItems, setThreadItems] = useImmer<IThreadItem[]>(() =>
        thread.threadItems.edges.map(({ node }) => ({
            id: node.id,
            role: node.role,
            content: node.content,
            toolName: node.toolName,
            toolArguments: node.toolArguments,
            context: node.context,
            threadItemRetrievalContext: node.threadItemRetrievalContext,
        })),
    );
    const [askAiResult, executeAskAi] = useMutation<ThreadAskAiMutation, ThreadAskAiMutationVariables>(askAiMutation);
    const [reAskAiResult, executeReAskAi] = useMutation<ThreadReAskAiMutation, ThreadReAskAiMutationVariables>(
        reAskAiMutation,
    );
    const [attachFileResult, executeAttachFile] = useMutation<
        ThreadAttachFileMutation,
        ThreadAttachFileMutationVariables
    >(attachFileMutation);
    const [attachKnowledgeResult, executeAttachKnowledge] = useMutation<
        ThreadAttachKnowledgeMutation,
        ThreadAttachKnowledgeMutationVariables
    >(attachKnowledgeMutation);
    const [attachWebpageResult, executeAttachWebpage] = useMutation<
        ThreadAttachWebpageMutation,
        ThreadAttachWebpageMutationVariables
    >(attachWebpageMutation);
    const [attachTranscriptionResult, executeAttachTranscription] = useMutation<
        ThreadAttachTranscriptionMutation,
        ThreadAttachTranscriptionMutationVariables
    >(attachTranscriptionMutation);

    const threadTokenCount = useTokenCount(personality, threadItems);
    const currentUser = useUserCurrent();

    const askAi = async (content: string) => {
        setThreadItems((draft) => {
            draft.push({
                id: null,
                role: "USER",
                content,
                context: false,
                toolName: null,
                toolArguments: null,
                threadItemRetrievalContext: null,
            });
        });
        const data: ThreadAskAiMutationVariables["data"] = {
            threadId: thread.id,
            role: "USER",
            content,
            personalityData: {
                name: personality.name,
                externalName: personality.externalName,
                temperature: personality.temperature,
                systemPrompt: personality.systemPrompt,
                includeRetrival: personality.includeRetrival,
                searchPrompt: personality.searchPrompt,
                tags: personality.tags,
                languages: personality.languages,
                model: personality.model,
                useCache: personality.useCache,
                showInChat: null,
            },
        };
        const result = await executeAskAi({ data });
        if (result.error) {
            notifications.show({
                title: "AI error",
                message: result.error.message,
            });
        }
        if (result.data) {
            setThreadItems((draft): IThreadItem[] => {
                return result.data!.threadAskAi.threadItems.edges.map(({ node }) => ({
                    id: node.id,
                    role: node.role,
                    content: node.content,
                    toolName: node.toolName,
                    toolArguments: node.toolArguments,
                    context: node.context,
                    threadItemRetrievalContext: node.threadItemRetrievalContext,
                }));
            });
            setThreadName(result.data!.threadAskAi.threadName);
        }
    };

    const reAskAi = async (item: IThreadItem) => {
        if (item.id === null) {
            return;
        }
        setThreadItems((draft) => {
            const idx = draft.findIndex((i) => i.id === item.id);
            return draft.slice(0, idx + 1);
        });
        const data: ThreadReAskAiMutationVariables["data"] = {
            itemId: item.id!,
            personalityData: {
                name: personality.name,
                externalName: personality.externalName,
                temperature: personality.temperature,
                systemPrompt: personality.systemPrompt,
                includeRetrival: personality.includeRetrival,
                searchPrompt: personality.searchPrompt,
                tags: personality.tags,
                languages: personality.languages,
                model: personality.model,
                useCache: personality.useCache,
                showInChat: null,
            },
        };
        const result = await executeReAskAi({ data });
        if (result.error) {
            notifications.show({
                title: "AI error",
                message: result.error.message,
            });
        }
        if (result.data) {
            setThreadItems((draft): IThreadItem[] => {
                return result.data!.threadReAskAi.threadItems.edges.map(({ node }) => ({
                    id: node.id,
                    role: node.role,
                    content: node.content,
                    toolName: node.toolName,
                    toolArguments: node.toolArguments,
                    context: node.context,
                    threadItemRetrievalContext: node.threadItemRetrievalContext,
                }));
            });
            setThreadName(result.data!.threadReAskAi.threadName);
        }
    };

    const attachFile = async (values: IAttachFileFormValues) => {
        let storedName: string;
        try {
            setUploading(true);
            storedName = await chunkedUpload({ file: values.file! });
        } catch (error) {
            let message: string;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = "Unknown error";
            }
            notifications.show({
                title: "Failed to upload file",
                message,
                color: "red",
            });
            return;
        } finally {
            setUploading(false);
        }

        const data: ThreadAttachFileMutationVariables["data"] = {
            threadId: thread.id,
            fileType: getFileType(values.file!)!,
            fileName: values.file!.name,
            file: storedName,
        };
        const result = await executeAttachFile({ data });
        if (result.error) {
            notifications.show({
                title: "Attach file failed",
                message: result.error.message,
            });
        }
        if (result.data) {
            setThreadItems((draft): IThreadItem[] => {
                return result.data!.threadAttachFile.threadItems.edges.map(({ node }) => ({
                    id: node.id,
                    role: node.role,
                    content: node.content,
                    toolName: node.toolName,
                    toolArguments: node.toolArguments,
                    context: node.context,
                    threadItemRetrievalContext: null,
                }));
            });
        }
    };

    const attachWebpage = async (values: IAttachWebpageFormValues) => {
        const data: ThreadAttachWebpageMutationVariables["data"] = {
            threadId: thread.id,
            url: values.url,
        };
        const result = await executeAttachWebpage({ data });
        if (result.error) {
            notifications.show({
                title: "Attach webpage failed",
                message: result.error.message,
            });
        }
        if (result.data) {
            setThreadItems((draft): IThreadItem[] => {
                return result.data!.threadAttachWebpage.threadItems.edges.map(({ node }) => ({
                    id: node.id,
                    role: node.role,
                    content: node.content,
                    toolName: node.toolName,
                    toolArguments: node.toolArguments,
                    context: node.context,
                    threadItemRetrievalContext: null,
                }));
            });
        }
    };

    const attachKnowledge = async (documentId: number) => {
        const result = await executeAttachKnowledge({ documentId, threadId: thread.id });
        if (result.error) {
            notifications.show({
                title: "Attach Document failed",
                message: result.error.message,
            });
        }
        if (result.data) {
            setThreadItems((draft): IThreadItem[] => {
                return result.data!.threadAttachKnowledge.threadItems.edges.map(({ node }) => ({
                    id: node.id,
                    role: node.role,
                    content: node.content,
                    toolName: node.toolName,
                    toolArguments: node.toolArguments,
                    context: node.context,
                    threadItemRetrievalContext: null,
                }));
            });
        }
    };

    const attachTranscription = async (transcriptionId: number) => {
        const result = await executeAttachTranscription({ transcriptionId, threadId: thread.id });
        if (result.error) {
            notifications.show({
                title: "Attach Transcript failed",
                message: result.error.message,
            });
        }
        if (result.data) {
            setThreadItems((draft): IThreadItem[] => {
                return result.data!.threadAttachTranscription.threadItems.edges.map(({ node }) => ({
                    id: node.id,
                    role: node.role,
                    content: node.content,
                    toolName: node.toolName,
                    toolArguments: node.toolArguments,
                    context: node.context,
                    threadItemRetrievalContext: null,
                }));
            });
        }
    };

    let interactArea = null;
    if (currentUser?.id === thread.user?.id) {
        const loadingString = getLoadingString(
            uploading,
            askAiResult,
            reAskAiResult,
            attachFileResult,
            attachKnowledgeResult,
            attachWebpageResult,
            attachTranscriptionResult,
        );
        interactArea = (
            <>
                {loadingString ? <Loading text={loadingString} /> : <ChatInput askAi={askAi} />}
                <Group>
                    <Text>Tokens: {threadTokenCount}</Text>
                    <div style={{ flexGrow: 1 }} />
                    <ForkButton thread={thread} />
                    <AttachModal
                        attachFile={attachFile}
                        attachFileFetching={attachFileResult.fetching}
                        attachKnowledge={attachKnowledge}
                        attachKnowledgeFetching={attachKnowledgeResult.fetching}
                        attachWebpage={attachWebpage}
                        attachWebpageFetching={attachWebpageResult.fetching}
                        attachTranscription={attachTranscription}
                        attachTranscriptionFetching={attachTranscriptionResult.fetching}
                    />
                </Group>
            </>
        );
    } else {
        interactArea = (
            <Group justify="space-between">
                <Text>Tokens: {threadTokenCount}</Text>
                <ForkButton thread={thread} />
            </Group>
        );
    }

    return (
        <>
            <Stack maw={800} mx="auto">
                <Title order={2}>{threadName || "New chat"}</Title>
                <Personality aiPersonality={personality} updatePersonality={setPersonalty} />
                {threadItems.map((item) => (
                    <ThreadItem key={itemKey++} item={item} reAskAi={reAskAi} />
                ))}
                {interactArea}
            </Stack>
            <Link to="..">Back</Link>
        </>
    );
}

export default Thread;
