import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useMutation } from "urql";
import ItemForm, { type IFormValues } from "./ItemForm";
import { type TuneThreadAddItemMutation, type TuneThreadAddItemMutationVariables, addItemMutation } from "./queries";

interface IProps {
    tuneThread: {
        id: number;
    };
    disabled: boolean;
}

function AddItemButton({ tuneThread, disabled }: IProps): JSX.Element {
    const [addResult, executeAdd] = useMutation<TuneThreadAddItemMutation, TuneThreadAddItemMutationVariables>(
        addItemMutation,
    );

    const add = async (values: IFormValues) => {
        const result = await executeAdd({
            data: {
                tuneThreadId: tuneThread.id,
                item: {
                    role: values.role,
                    content: values.content,
                    toolCallId: values.toolCallId || null,
                    toolName: values.toolName || null,
                    toolArguments:
                        Object.keys(values.toolArguments).length > 0 ? JSON.stringify(values.toolArguments) : null,
                    context: false,
                },
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to add item",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Item added",
                message: "Item added to thread",
            });
        }
    };

    const onClick = () =>
        modals.open({
            title: "Add item to thread",
            children: (
                <ItemForm
                    fetching={addResult.fetching}
                    handleSubmit={(values) => {
                        add(values);
                        modals.closeAll();
                    }}
                />
            ),
        });

    return (
        <Button onClick={onClick} disabled={disabled || addResult.fetching}>
            Add item
        </Button>
    );
}

export default AddItemButton;
