import { ActionIcon, Box, Button, Group, TextInput } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

interface IProps {
    value: Record<string, string>;
    onChange: (value: Record<string, string>) => void;
}

function ToolArgumentsInput({ value, onChange }: IProps) {
    const handleKeyChange = (oldKey: string, newKey: string) => {
        const newValue = { ...value };
        delete newValue[oldKey];
        newValue[newKey] = value[oldKey] || "";
        onChange(newValue);
    };

    const handleValueChange = (key: string, newValue: string) => {
        onChange({ ...value, [key]: newValue });
    };

    const addArgument = () => {
        onChange({ ...value, "": "" });
    };

    const removeArgument = (key: string) => {
        const newValue = { ...value };
        delete newValue[key];
        onChange(newValue);
    };

    return (
        <Box>
            {Object.entries(value).map(([key, val], index) => (
                <Group key={index} align="flex-start" mb="xs">
                    <TextInput
                        placeholder="Key"
                        value={key}
                        onChange={(e) => handleKeyChange(key, e.target.value)}
                        style={{ flex: 1 }}
                    />
                    <TextInput
                        placeholder="Value"
                        value={val}
                        onChange={(e) => handleValueChange(key, e.target.value)}
                        style={{ flex: 1 }}
                    />
                    <ActionIcon color="red" onClick={() => removeArgument(key)}>
                        <IconTrash size="1rem" />
                    </ActionIcon>
                </Group>
            ))}
            <Button variant="light" leftSection={<IconPlus size="1rem" />} onClick={addArgument} size="sm">
                Add Argument
            </Button>
        </Box>
    );
}

export default ToolArgumentsInput;
