import { Button, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./App.module.css";
import AppRoutes from "./AppRoutes";

function App(): JSX.Element {
    return (
        <div className={classes.grid}>
            <header>
                <Title ta="center">TalentMiles AI</Title>
                <Button component="a" href="/logout">
                    Logout
                </Button>
            </header>
            <nav className={classes.nav}>
                <Link to="threads">Chat with AI</Link>
                <Link to="generate-images">Generate images</Link>
                <Link to="documents">Knowledge database</Link>
                <Link to="ai-personalities">AI personalities</Link>
                <Link to="users">Users</Link>
                <Link to="externals">Externals</Link>
                <Link to="transcriptions">Transcribe audio</Link>
                <Link to="cache">Cache</Link>
            </nav>
            <main>
                <AppRoutes />
            </main>
            <footer className={classes.footer}>TalentMiles ©</footer>
        </div>
    );
}

export default App;
