import { useParams } from "react-router-dom";
import { useQuery } from "urql";

import { type ThreadQueryVariables, threadQuery, type ThreadQuery } from "./queries";
import { Box } from "@mantine/core";
import Thread from "./Thread";
import Loading from "../Loading";

function LoadThread(): JSX.Element {
    const { threadId } = useParams();
    const [threadResult] = useQuery<ThreadQuery, ThreadQueryVariables>({
        query: threadQuery,
        variables: {
            threadId: Number.parseInt(threadId!),
        },
    });

    if (threadResult.fetching) {
        return <Loading />;
    }
    if (threadResult.error) {
        return <Box>Error: {threadResult.error.message}</Box>;
    }
    const thread = threadResult.data?.thread;
    if (!thread) {
        return <Box>Error: Failed to load thread</Box>;
    }
    return <Thread thread={thread!} />;
}

export default LoadThread;
