import { useQuery } from "urql";
import { Box, Button, Group, Table, Title, Pagination } from "@mantine/core";
import { Link } from "react-router-dom";
import { useState } from "react";

import { userPageQuery, type UserPageQuery, type UserPageQueryVariables } from "./queries";
import DeleteButton from "./DeleteButton";
import PasswordButton from "./PasswordButton";
import classes from "./Users.module.css";
import Loading from "../Loading";

function Users(): JSX.Element {
    const [page, setPage] = useState(1);
    const [result] = useQuery<UserPageQuery, UserPageQueryVariables>({
        query: userPageQuery,
        variables: {
            page,
        },
    });

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.userPage.maxPage === 0) {
        main = <Box>No users</Box>;
    } else {
        main = (
            <>
                <Pagination total={result.data.userPage.maxPage} value={page} onChange={setPage} />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>ID</Table.Th>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>Email</Table.Th>
                            <Table.Th>Created</Table.Th>
                            <Table.Th colSpan={3} />
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {result.data.userPage.items.map((user) => (
                            <Table.Tr key={user.id}>
                                <Table.Td>{user.id}</Table.Td>
                                <Table.Td>
                                    {user.firstName} {user.lastName}
                                </Table.Td>
                                <Table.Td>{user.email}</Table.Td>
                                <Table.Td>{new Date(user.created).toLocaleString("en-GB")}</Table.Td>
                                <Table.Td>
                                    <Button component={Link} to={`${user.id}/edit`}>
                                        Edit
                                    </Button>
                                </Table.Td>
                                <Table.Td>
                                    <PasswordButton user={user} />
                                </Table.Td>
                                <Table.Td>
                                    <DeleteButton user={user} />
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
                <Pagination total={result.data.userPage.maxPage} value={page} onChange={setPage} />
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>Users</Title>
            <Group>
                <Button component={Link} to="add">
                    Add user
                </Button>
            </Group>
            {main}
        </Box>
    );
}

export default Users;
