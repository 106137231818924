import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";

import {
    type TuneThreadDeleteItemMutationVariables,
    deleteItemMutation,
    type TuneThreadDeleteItemMutation,
} from "./queries";
import ActionButton from "../../ActionButton";

interface IProps {
    tuneThreadId: number;
    tuneThreadItemId: number;
}

function DeleteItemButton({ tuneThreadId, tuneThreadItemId }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<
        TuneThreadDeleteItemMutation,
        TuneThreadDeleteItemMutationVariables
    >(deleteItemMutation);

    const doDelete = async () => {
        const result = await executeDelete({ data: { tuneThreadId, tuneThreadItemId } });
        if (result.error) {
            notifications.show({
                title: "Failed to delete item",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Item deleted",
                message: "Item deleted",
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete item?",
            children: <Text size="sm">Are you sure you want to delete this item?</Text>,
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <ActionButton
            onClick={onClick}
            label="Delete item"
            icon={<IconTrash stroke={1} />}
            disabled={deleteResult.fetching}
        />
    );
}

export default DeleteItemButton;
