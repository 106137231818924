import { Box, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import Loading from "../Loading";
import { chunkedUpload } from "../chunkedUploader";
import { getFileType } from "../utils";
import DocumentForm, { type IFormValues } from "./DocumentForm";
import {
    type DocumentEditMutation,
    type DocumentEditMutationVariables,
    type DocumentQuery,
    type DocumentQueryVariables,
    documentQuery,
    editMutation,
} from "./queries";

function Edit(): JSX.Element {
    const { documentId } = useParams();
    const navigate = useNavigate();
    const [uploading, setUploading] = useState(false);
    const [editResult, executeEdit] = useMutation<DocumentEditMutation, DocumentEditMutationVariables>(editMutation);
    const [result] = useQuery<DocumentQuery, DocumentQueryVariables>({
        query: documentQuery,
        variables: {
            documentId: Number.parseInt(documentId!),
        },
    });

    const handleSubmit = async (values: IFormValues) => {
        let storedName = "";
        if (values.file) {
            try {
                setUploading(true);
                storedName = await chunkedUpload({ file: values.file! });
            } catch (error) {
                let message: string;
                if (error instanceof Error) {
                    message = error.message;
                } else {
                    message = "Unknown error";
                }
                notifications.show({
                    title: "Failed to upload file",
                    message,
                    color: "red",
                });
                return;
            } finally {
                setUploading(false);
            }
        }

        const result = await executeEdit({
            data: {
                id: Number.parseInt(documentId!),
                documentType: values.documentType,
                name: values.name,
                content: values.content || null,
                url: values.url || null,
                fileData: values.file
                    ? { fileType: getFileType(values.file)!, fileName: values.file.name, file: storedName }
                    : null,
                tags: values.tags,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save Document",
                message: result.error.message,
            });
        } else {
            const document = result.data?.documentEdit;
            notifications.show({
                title: "Document saved",
                message: `Saved Document ${document!.name}`,
            });
            navigate("..");
        }
    };

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const document = result.data?.document;
        if (!document) {
            main = <Box>Document not found</Box>;
        } else {
            const initialValues = {
                documentType: document.documentType,
                name: document.name,
                content: document.content || "",
                url: document.url || "",
                file: null,
                tags: document.tags,
            };
            main = (
                <DocumentForm
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    edit={true}
                    fetching={editResult.fetching || uploading}
                    filePlaceholder={document.file?.fileName}
                />
            );
        }
    }

    return (
        <>
            <Title order={2}>Edit Document</Title>
            {main}
            <Link to="..">Back</Link>
        </>
    );
}

export default Edit;
