import { Route, Routes } from "react-router-dom";

import Externals from "./Externals";
import Add from "./Add";
import Edit from "./Edit";

function ExternalRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/add" element={<Add />} />
            <Route path="/:externalId/edit" element={<Edit />} />
            <Route path="/" element={<Externals />} />
        </Routes>
    );
}

export default ExternalRoutes;
