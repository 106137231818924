import { Button, FileInput, Group, LoadingOverlay, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { getMime } from "../utils";
import PersonalityInput from "./PersonalityInput";

export interface IFormValues {
    file: File | null;
    name: string;
    aiPersonality: string;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    initialValues?: IFormValues;
    fetching: boolean;
}

function TranscriptionForm({ handleSubmit, initialValues, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: initialValues ?? {
            file: null,
            name: "",
            aiPersonality: "",
        },
        validate: {
            name: (value) => (value ? null : "Required"),
            file: (value, values) => {
                if (!value) {
                    return "Required";
                }
                if (value.size / 1024 / 1024 > 2000) {
                    return "File size too big";
                }
                if (!getMime("AUDIO").includes(value.type)) {
                    return "Unsupported audio format";
                }
                return null;
            },
        },
    });

    const handleFileChange = (file: File | null) => {
        const previousFile = form.values.file;
        form.setFieldValue("file", file);

        if (file && (!form.values.name || (previousFile && form.values.name === previousFile.name))) {
            form.setFieldValue("name", file.name);
        }
    };

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto">
                <LoadingOverlay visible={fetching} />
                <FileInput
                    label="Select file"
                    withAsterisk
                    fileInputProps={{ accept: getMime("AUDIO").join(",") }}
                    {...form.getInputProps("file")}
                    onChange={handleFileChange}
                />
                <TextInput withAsterisk label="Name" {...form.getInputProps("name")} />
                <PersonalityInput {...form.getInputProps("aiPersonality")} />
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={fetching}>
                        Save
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default TranscriptionForm;
