import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import ImportModal from "./ImportModal";

interface IProps {
    aiPersonality: {
        id: number;
        publicId: string;
        name: string;
    };
}

function ImportButton({ aiPersonality }: IProps): JSX.Element {
    const onClick = () =>
        modals.open({
            size: "xl",
            title: "Import threads",
            children: (
                <ImportModal
                    aiPersonality={aiPersonality}
                    close={() => {
                        modals.closeAll();
                    }}
                />
            ),
        });

    return <Button onClick={onClick}>Import Threads</Button>;
}

export default ImportButton;
