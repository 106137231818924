import { Box, Group, List, Text, Title } from "@mantine/core";
import { useQuery } from "urql";
import InfoNode from "../InfoNode";
import Loading from "../Loading";
import classes from "./AppCaches.module.css";
import ClearCacheButton from "./ClearCacheButton";
import { type OpenaiCacheInfoQuery, openaiCacheInfoQuery } from "./queries";

function AppCaches(): JSX.Element {
    const [result, refetch] = useQuery<OpenaiCacheInfoQuery>({
        query: openaiCacheInfoQuery,
        requestPolicy: "cache-and-network",
    });

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data) {
        main = <Box>Error: No result</Box>;
    } else {
        main = (
            <>
                <Group>
                    <ClearCacheButton refetch={refetch} names={result.data.openaiCacheNames} />
                </Group>
                <InfoNode title="Total size">
                    <Text>{result.data.openaiCacheSize}</Text>
                </InfoNode>
                <InfoNode title="Cache distribution per month">
                    <Box>
                        <List>
                            {result.data.openaiCacheStats.date.map((datum) => (
                                <List.Item key={datum.month}>
                                    {datum.month}: {datum.count}
                                </List.Item>
                            ))}
                        </List>
                    </Box>
                </InfoNode>
                <InfoNode title="Cache distribution per personality">
                    <Box>
                        <List>
                            {result.data.openaiCacheStats.personality.map((datum) => (
                                <List.Item key={datum.name}>
                                    {datum.name}: {datum.count}
                                </List.Item>
                            ))}
                        </List>
                    </Box>
                </InfoNode>
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>Cache statistics</Title>
            {main}
        </Box>
    );
}

export default AppCaches;
