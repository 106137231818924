import { useQuery } from "urql";

import { listQuery, type AiPersonalityListQuery } from "./queries";
import { Box, Button, Group, Table, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import DeleteButton from "./DeleteButton";
import classes from "./AiPersonalities.module.css";
import Loading from "../Loading";

function AiPersonalities(): JSX.Element {
    const [result] = useQuery<AiPersonalityListQuery>({
        query: listQuery,
    });

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.aiPersonalityList.length === 0) {
        main = <Box>No AI personalities</Box>;
    } else {
        main = (
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>External name</Table.Th>
                        <Table.Th>Model</Table.Th>
                        <Table.Th>Created</Table.Th>
                        <Table.Th colSpan={2} />
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {result.data.aiPersonalityList.map((aiPersonality) => (
                        <Table.Tr key={aiPersonality.id}>
                            <Table.Td>{aiPersonality.name}</Table.Td>
                            <Table.Td>{aiPersonality.externalName}</Table.Td>
                            <Table.Td>{aiPersonality.model}</Table.Td>
                            <Table.Td>{new Date(aiPersonality.created).toLocaleString("en-GB")}</Table.Td>
                            <Table.Td>
                                <Button component={Link} to={`${aiPersonality.id}/edit`}>
                                    Edit
                                </Button>
                            </Table.Td>
                            <Table.Td>
                                <Button component={Link} to={`${aiPersonality.id}/tune`}>
                                    Tune
                                </Button>
                            </Table.Td>
                            <Table.Td>
                                <DeleteButton aiPersonality={aiPersonality} />
                            </Table.Td>
                        </Table.Tr>
                    ))}
                </Table.Tbody>
            </Table>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>AI personalities</Title>
            <Group>
                <Button component={Link} to="add">
                    Add AI personality
                </Button>
            </Group>
            {main}
        </Box>
    );
}

export default AiPersonalities;
