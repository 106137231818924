import { useQuery } from "urql";
import { Box, Button, Group, Table, Title, Pagination, Checkbox, Badge, Tooltip } from "@mantine/core";
import { Link } from "react-router-dom";
import { useState } from "react";

import { externalPageQuery, type ExternalPageQuery, type ExternalPageQueryVariables } from "./queries";
import DeleteButton from "./DeleteButton";
import classes from "./Externals.module.css";
import { IconCopy } from "@tabler/icons-react";
import { useClipboard } from "@mantine/hooks";
import Loading from "../Loading";
import ActionButton from "../ActionButton";

function Externals(): JSX.Element {
    const [page, setPage] = useState(1);
    const [result] = useQuery<ExternalPageQuery, ExternalPageQueryVariables>({
        query: externalPageQuery,
        variables: {
            page,
        },
    });
    const clipboard = useClipboard();

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.externalPage.maxPage === 0) {
        main = <Box>No external access rights have been defined</Box>;
    } else {
        main = (
            <>
                <Pagination total={result.data.externalPage.maxPage} value={page} onChange={setPage} />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>External Id</Table.Th>
                            <Table.Th>Document parsing</Table.Th>
                            <Table.Th>Embeddings</Table.Th>
                            <Table.Th>Transcribe</Table.Th>
                            <Table.Th>Enabled personalities</Table.Th>
                            <Table.Th colSpan={2} />
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {result.data.externalPage.items.map((external) => (
                            <Table.Tr key={external.id}>
                                <Table.Td>{external.name}</Table.Td>
                                <Table.Td>
                                    <Group wrap="nowrap" gap="xs">
                                        {external.publicId}
                                        <ActionButton
                                            onClick={() => clipboard.copy(external.publicId)}
                                            label="Copy external id"
                                            icon={<IconCopy stroke={1} />}
                                            size="sm"
                                        />
                                    </Group>
                                </Table.Td>
                                <Table.Td>
                                    <Checkbox.Indicator checked={external.allowDocumentIntelligence} />
                                </Table.Td>
                                <Table.Td>
                                    <Checkbox.Indicator checked={external.allowEmbeddings} />
                                </Table.Td>
                                <Table.Td>
                                    <Checkbox.Indicator checked={external.allowTranscribe} />
                                </Table.Td>
                                <Table.Td>
                                    <Group gap="xs">
                                        {external.externalPersonalities.edges.map(({ node: { aiPersonality } }) => (
                                            <Tooltip label="Copy personality external id" key={aiPersonality.id}>
                                                <Badge
                                                    size="xs"
                                                    onClick={() => clipboard.copy(aiPersonality.publicId)}
                                                    className={classes.clickBadge}
                                                >
                                                    {aiPersonality.name}
                                                </Badge>
                                            </Tooltip>
                                        ))}
                                    </Group>
                                </Table.Td>
                                <Table.Td>
                                    <Button component={Link} to={`${external.id}/edit`}>
                                        Edit
                                    </Button>
                                </Table.Td>
                                <Table.Td>
                                    <DeleteButton external={external} />
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
                <Pagination total={result.data.externalPage.maxPage} value={page} onChange={setPage} />
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>External access rights</Title>
            <Group>
                <Button component={Link} to="add">
                    Add external access rights
                </Button>
            </Group>
            {main}
        </Box>
    );
}

export default Externals;
