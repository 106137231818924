import { useQuery } from "urql";
import { Box, Title, Pagination, Image } from "@mantine/core";
import { Link } from "react-router-dom";
import { useState } from "react";

import { generatedImagePage, type GeneratedImagePageQuery, type GeneratedImagePageQueryVariables } from "./queries";
import classes from "./PreviousImages.module.css";
import ImageModal from "./ImageModal";
import Loading from "../Loading";

type IImage = GeneratedImagePageQuery["generatedImagePage"]["items"][0];

function PreviousImages(): JSX.Element {
    const [page, setPage] = useState(1);
    const [result] = useQuery<GeneratedImagePageQuery, GeneratedImagePageQueryVariables>({
        query: generatedImagePage,
        variables: {
            page,
        },
    });
    const [selectedImage, setSelectedImage] = useState<IImage | null>(null);

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.generatedImagePage.maxPage === 0) {
        main = <Box>No previous images</Box>;
    } else {
        main = (
            <>
                <Pagination total={result.data.generatedImagePage.maxPage} value={page} onChange={setPage} />
                <Box className={classes.imageGrid}>
                    {result.data.generatedImagePage.items.map((img) => (
                        <Box key={img.id} onClick={() => setSelectedImage(img)}>
                            <Image
                                src={img.file?.src}
                                width={300}
                                height={300 / 1.75}
                                fit="contain"
                                radius="sm"
                                loading="lazy"
                            />
                        </Box>
                    ))}
                </Box>
                <Pagination total={result.data.generatedImagePage.maxPage} value={page} onChange={setPage} />
                <ImageModal image={selectedImage} close={() => setSelectedImage(null)} showDelete />
            </>
        );
    }

    return (
        <Box className={classes.grid}>
            <Title order={2}>Perviously generated images</Title>
            {main}
            <Link to="..">Back</Link>
        </Box>
    );
}

export default PreviousImages;
