import { useMutation } from "urql";
import { useImmerReducer } from "use-immer";

import type { IFormValues } from "../AiPersonalityForm";
import {
    type AiPersonalityQuery,
    type AskAiJudgeMutation,
    type AskAiJudgeMutationVariables,
    type AskAiMutation,
    type AskAiMutationVariables,
    askAiJudgeMutation,
    askAiMutation,
} from "./queries";

type IAiPersonality = Exclude<AiPersonalityQuery["aiPersonality"], null>;

export type IThreadTestState =
    | {
          status: "ERROR";
          error: string;
      }
    | {
          status: "LOADING";
      }
    | {
          status: "SUCCESS";
          items: AskAiMutation["tuneThreadAskAi"];
          judgement: AskAiJudgeMutation["tuneThreadAskAiJudge"] | null;
      };

type IState = Record<number, IThreadTestState>;

const initialState: IState = {};

type IAction =
    | { type: "START_LOADING"; ids: number[] }
    | { type: "RESULT_ERROR"; id: number; error: string }
    | {
          type: "RESULT_SUCCESS";
          id: number;
          items: AskAiMutation["tuneThreadAskAi"];
          judgement: AskAiJudgeMutation["tuneThreadAskAiJudge"] | null;
      };

function reducer(draft: IState, action: IAction) {
    switch (action.type) {
        case "START_LOADING":
            return action.ids.reduce((acc, id) => {
                acc[id] = { status: "LOADING" };
                return acc;
            }, {} as IState);
        case "RESULT_ERROR":
            draft[action.id] = { status: "ERROR", error: action.error };
            break;
        case "RESULT_SUCCESS":
            draft[action.id] = { status: "SUCCESS", items: action.items, judgement: action.judgement };
            break;
    }
}

export default function useTest(
    aiPersonality: IAiPersonality,
): [boolean, (values: IFormValues) => void, IState, number | null] {
    const [state, dispatch] = useImmerReducer(reducer, initialState);
    const [, executeAskAi] = useMutation<AskAiMutation, AskAiMutationVariables>(askAiMutation);
    const [, executeAskAiJudge] = useMutation<AskAiJudgeMutation, AskAiJudgeMutationVariables>(askAiJudgeMutation);

    const handleTest = async (values: IFormValues) => {
        const tuneThreadIds = aiPersonality.tuneThreads.edges.map(({ node }) => node.id);
        dispatch({ type: "START_LOADING", ids: tuneThreadIds });

        const promises = aiPersonality.tuneThreads.edges.map(async ({ node: tuneThread }) => {
            try {
                const askAiResult = await executeAskAi({
                    data: {
                        tuneThreadId: tuneThread.id,
                        personalityData: {
                            name: values.name,
                            externalName: values.externalName,
                            systemPrompt: values.systemPrompt,
                            temperature: values.temperature,
                            includeRetrival: values.includeRetrival,
                            searchPrompt: values.includeRetrival ? values.searchPrompt : null,
                            tags: values.tags,
                            languages: values.languages,
                            model: values.model,
                            useCache: values.useCache,
                            showInChat: null,
                        },
                        items: tuneThread.tuneThreadItems.edges.map(({ node }) => ({
                            role: node.role,
                            content: node.content,
                            context: node.context,
                            toolCallId: node.toolCallId,
                            toolName: node.toolName,
                            toolArguments: node.toolArguments,
                        })),
                    },
                });

                if (askAiResult.error) {
                    throw new Error(askAiResult.error.message);
                }

                const items = askAiResult.data!.tuneThreadAskAi;
                const last = items[items.length - 1];

                let judgement: AskAiJudgeMutation["tuneThreadAskAiJudge"] | null = null;

                if (tuneThread.llmJudge) {
                    const askAiJudgeResult = await executeAskAiJudge({
                        data: {
                            tuneThreadId: tuneThread.id,
                            response: last.content,
                        },
                    });

                    if (askAiJudgeResult.error) {
                        throw new Error(askAiJudgeResult.error.message);
                    }

                    judgement = askAiJudgeResult.data!.tuneThreadAskAiJudge;
                }

                dispatch({
                    type: "RESULT_SUCCESS",
                    id: tuneThread.id,
                    items: items,
                    judgement: judgement,
                });
            } catch (error: any) {
                dispatch({ type: "RESULT_ERROR", id: tuneThread.id, error: error.toString() });
            }
        });

        await Promise.allSettled(promises);
    };

    const anyLoading = Object.values(state).some((val) => val.status === "LOADING");
    let testScore: number | null = 0;
    let count = 0;
    for (const ithread of Object.values(state)) {
        if (ithread.status !== "SUCCESS") {
            continue;
        }
        if (ithread.judgement !== null) {
            count++;
            testScore += ithread.judgement.score;
        }
    }
    if (count > 0) {
        testScore = testScore / count;
    } else {
        testScore = null;
    }
    return [anyLoading, handleTest, state, testScore];
}
