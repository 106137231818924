import { Accordion, Button, Group, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { zodResolver } from "mantine-form-zod-resolver";
import { useMemo } from "react";
import { z } from "zod";

import InfoNode from "../../InfoNode";
import type { IParsedInput } from "./ImportInputForm";
import classes from "./ImportSelectForm.module.css";

export interface IFormValues {
    threads: {
        id: number;
        discriminant: string;
        personality: string;
        personalityName: string | null;
        items: {
            id: number;
            role: "user" | "assistant" | "tool";
            content: string;
            toolName: string | null;
            toolArguments: string | null;
            hide: boolean;
            rating: "up" | "down" | null;
        }[];
    }[];
}

interface IProps {
    aiPersonality: {
        id: number;
        publicId: string;
        name: string;
    };
    handleSubmit: (values: IFormValues) => void;
    parsedData: IParsedInput;
}

function useInitialValues(parsedData: IParsedInput): IFormValues {
    return useMemo(() => {
        const threads: IFormValues["threads"] = [];
        for (const parsedThread of parsedData.rated_ai_thread) {
            for (let i = 0; i < parsedThread.ai_thread_items.length; i++) {
                const parsedItem = parsedThread.ai_thread_items[i];
                if (parsedItem.rating === null) {
                    continue;
                }
                const prevItems = parsedThread.ai_thread_items.slice(0, i);
                const thread: IFormValues["threads"][0] = {
                    id: parsedThread.id,
                    discriminant: parsedThread.discriminant,
                    personality: parsedThread.personality,
                    personalityName: parsedThread.personality_name,
                    items: prevItems.map((item) => ({
                        id: item.id,
                        role: item.role,
                        content: item.content,
                        toolName: item.tool_name,
                        toolArguments: item.tool_arguments,
                        hide: item.hide,
                        rating: null,
                    })),
                };
                thread.items.push({
                    id: parsedItem.id,
                    role: parsedItem.role,
                    content: parsedItem.content,
                    toolName: parsedItem.tool_name,
                    toolArguments: parsedItem.tool_arguments,
                    hide: parsedItem.hide,
                    rating: parsedItem.rating,
                });
                threads.push(thread);
            }
        }
        return { threads };
    }, [parsedData]);
}

const schema = z.object({
    threads: z
        .array(
            z.object({
                id: z.number(),
                discriminant: z.string(),
                personality: z.string(),
                personality_name: z.string().nullable(),
                items: z
                    .array(
                        z.object({
                            id: z.number(),
                            role: z.enum(["user", "assistant"]),
                            content: z.string(),
                            tool_name: z.string().nullable(),
                            tool_arguments: z.string().nullable(),
                            hide: z.boolean(),
                            rating: z.union([z.literal("up"), z.literal("down"), z.null()]),
                        }),
                    )
                    .min(1, { message: "Thread must have at least one item." })
                    .refine(
                        (items) => {
                            for (let i = 0; i < items.length - 1; i++) {
                                if (items[i].rating !== null) return false;
                            }
                            return items[items.length - 1].rating !== null;
                        },
                        {
                            message:
                                "All items except the last must have a rating of 'null', and the last item must not have a rating of 'null'.",
                        },
                    ),
            }),
        )
        .min(1, { message: "You must provide at least one thread." }),
});

function ImportSelectForm({ aiPersonality, handleSubmit, parsedData }: IProps): JSX.Element {
    const initialValues = useInitialValues(parsedData);

    const form = useForm<IFormValues>({
        initialValues,
        validate: zodResolver(schema) as any,
    });

    const removeOthers = () => {
        form.setFieldValue(
            "threads",
            form.getValues().threads.filter((thread) => thread.personality === aiPersonality.publicId),
        );
    };

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Group mb="md">
                <Button type="button" onClick={removeOthers} color="red">
                    Remove other personality threads
                </Button>
            </Group>
            <Accordion variant="contained">
                {form.getValues().threads.map((thread, index) => (
                    <Accordion.Item key={thread.id} value={thread.id.toString()}>
                        <Accordion.Control>
                            {thread.discriminant} / {thread.id} / {thread.personalityName}
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Group mb="md">
                                <Button type="button" onClick={() => form.removeListItem("threads", index)}>
                                    Remove
                                </Button>
                            </Group>
                            <Accordion classNames={classes} variant="contained">
                                {thread.items.map((item, itemIdx) => (
                                    <Accordion.Item key={item.id} value={item.id.toString()}>
                                        <Accordion.Control>
                                            {item.role} {item.id}
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack py="md" gap="md">
                                                <Group>
                                                    <Button
                                                        type="button"
                                                        onClick={() =>
                                                            form.removeListItem(`threads.${index}.items`, itemIdx)
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </Group>
                                                <InfoNode title="Role" order={4}>
                                                    <Text span>{item.role}</Text>
                                                </InfoNode>
                                                {item.rating && (
                                                    <InfoNode title="Rating" order={4}>
                                                        <Text span>{item.rating}</Text>
                                                    </InfoNode>
                                                )}
                                                <InfoNode title="Content" order={4}>
                                                    <Text className={classes.content}>{item.content}</Text>
                                                </InfoNode>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Accordion.Panel>
                    </Accordion.Item>
                ))}
            </Accordion>
            <Group justify="flex-end" mt="md">
                <Button type="button" onClick={() => form.reset()} color="red">
                    Reset
                </Button>
                <Button type="submit">Import</Button>
            </Group>
        </form>
    );
}

export default ImportSelectForm;
