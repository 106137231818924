import { Route, Routes } from "react-router-dom";

import SelectPersonality from "./SelectPersonality";
import LoadThread from "./LoadThread";
import PreviousThreads from "./PreviousThreads";

function ThreadRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<SelectPersonality />} />
            <Route path="/:threadId" element={<LoadThread />} />
            <Route path="/previous" element={<PreviousThreads />} />
        </Routes>
    );
}

export default ThreadRoutes;
