import { useForm } from "@mantine/form";

import { Button, Group, LoadingOverlay, Stack, TextInput, Checkbox } from "@mantine/core";
import PersonalitiesInput from "./PersonalitiesInput";

export interface IFormValues {
    name: string;
    allowDocumentIntelligence: boolean;
    allowEmbeddings: boolean;
    allowTranscribe: boolean;
    aiPersonalities: string[];
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    initialValues?: IFormValues;
    fetching: boolean;
}

function ExternalForm({ handleSubmit, initialValues, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: initialValues ?? {
            name: "",
            allowDocumentIntelligence: false,
            allowEmbeddings: false,
            allowTranscribe: false,
            aiPersonalities: [],
        },
        validate: {
            name: (value) => (value ? null : "Required"),
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto">
                <LoadingOverlay visible={fetching} />
                <TextInput withAsterisk label="Name" {...form.getInputProps("name")} />
                <Checkbox label="Allow document intelligence" {...form.getInputProps("allowDocumentIntelligence")} />
                <Checkbox label="Allow embeddings" {...form.getInputProps("allowEmbeddings")} />
                <Checkbox label="Allow transcribe" {...form.getInputProps("allowTranscribe")} />
                <PersonalitiesInput {...form.getInputProps("aiPersonalities")} />
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={fetching}>
                        Save
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default ExternalForm;
