import { createRoot } from "react-dom/client";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { MantineProvider, createTheme } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { Client, Provider as UrqlProvider, cacheExchange, fetchExchange } from "urql";

import App from "./App";
import { UserCurrentProvider } from "./CurrentUser";
import { SiteConfigProvider } from "./SiteConfig";

const client = new Client({
    url: "/graphql",
    exchanges: [cacheExchange, fetchExchange],
});

const theme = createTheme({
    /** Put your mantine theme override here */
});

function init() {
    Sentry.init({
        dsn: window.__CONFIG__.SENTRY_DSN,
        release: window.__CONFIG__.RELEASE,
    });

    try {
        const container = document.getElementById("root");
        if (!container) {
            throw new Error("Failed to find the root element");
        }
        const root = createRoot(container);
        root.render(
            <MantineProvider theme={theme}>
                <UrqlProvider value={client}>
                    <ModalsProvider>
                        <BrowserRouter>
                            <Sentry.ErrorBoundary fallback={<p>"An error has occurred"</p>}>
                                <SiteConfigProvider>
                                    <UserCurrentProvider>
                                        <Notifications />
                                        <App />
                                    </UserCurrentProvider>
                                </SiteConfigProvider>
                            </Sentry.ErrorBoundary>
                        </BrowserRouter>
                    </ModalsProvider>
                </UrqlProvider>
            </MantineProvider>,
        );
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
}

init();
