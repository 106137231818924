import { Route, Routes } from "react-router-dom";

import AiPersonalities from "./AiPersonalities";
import Add from "./Add";
import Edit from "./Edit";
import TuneLoader from "./tuneThreads/TuneLoader";

function AiPersonalityRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/add" element={<Add />} />
            <Route path="/:aiPersonalityId/edit" element={<Edit />} />
            <Route path="/:aiPersonalityId/tune" element={<TuneLoader />} />
            <Route path="/" element={<AiPersonalities />} />
        </Routes>
    );
}

export default AiPersonalityRoutes;
