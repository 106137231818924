import { IconCopy } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import ActionButton from "../ActionButton";

interface IProps {
    image: {
        file: {
            src: string;
        } | null;
    };
}

function CopyButton({ image }: IProps): JSX.Element {
    const copy = async () => {
        try {
            const response = await fetch(image!.file!.src);
            const blob = await response.blob();
            const item = new ClipboardItem({
                [blob.type]: blob,
            });
            await navigator.clipboard.write([item]);
            notifications.show({
                title: "Copied",
                message: "Image copied to clipboard",
            });
        } catch (error) {
            console.error("Error copying image: ", error);
            notifications.show({
                title: "Copy error",
                message: "Failed to copy image to clipboard",
            });
        }
    };
    return <ActionButton onClick={copy} label="Copy image" icon={<IconCopy stroke={1} />} size="lg" />;
}

export default CopyButton;
