import { ActionIcon, Anchor, Box, Text, Title } from "@mantine/core";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowBarDown, IconArrowBarToUp, IconCopy, IconFileDiff } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "urql";
import ActionButton from "../ActionButton";
import InfoNode from "../InfoNode";
import Loading from "../Loading";
import { languageLabel, processingStatusLabel, useProcessing } from "../utils";
import DiffModal from "./DiffModal";
import classes from "./Transcription.module.css";
import { type TranscriptionQuery, type TranscriptionQueryVariables, transcriptionQuery } from "./queries";

function Transcription(): JSX.Element {
    const { transcriptionId } = useParams();
    const [transcribedOpened, transcribedFuncs] = useDisclosure(false);
    const [cleanedOpened, cleanedFuncs] = useDisclosure(false);
    const [result, execute] = useQuery<TranscriptionQuery, TranscriptionQueryVariables>({
        query: transcriptionQuery,
        variables: {
            transcriptionId: Number.parseInt(transcriptionId!),
        },
    });
    const clipboard = useClipboard();

    useProcessing(result, execute, (result) => result.data?.transcription?.processingStatus === "PROCESSING");

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const transcription = result.data?.transcription;
        if (!transcription) {
            main = <Box>Transcription not found</Box>;
        } else {
            const copyTranscribed = () => {
                clipboard.copy(transcription.extractedText);
            };
            const copyCleaned = () => {
                clipboard.copy(transcription.cleanedText);
            };
            const openDiffModal = () => {
                modals.open({
                    size: "80vw",
                    title: "Import threads",
                    children: (
                        <DiffModal source={transcription.extractedText ?? ""} dest={transcription.cleanedText ?? ""} />
                    ),
                });
            };

            main = (
                <>
                    <InfoNode title="Processing status">
                        <Text span>{processingStatusLabel(transcription.processingStatus)}</Text>
                    </InfoNode>
                    <InfoNode title="Name">
                        <Text span>{transcription.name}</Text>
                    </InfoNode>
                    <InfoNode title="Created">
                        <Text span>{new Date(transcription.created).toLocaleString("en-GB")}</Text>
                    </InfoNode>
                    <InfoNode title="Language">
                        <Text span>{languageLabel(transcription.language)}</Text>
                    </InfoNode>
                    <InfoNode title="File">
                        <Text>
                            <Anchor href={transcription.file?.src} target="_blank">
                                {transcription.file?.fileName}
                            </Anchor>
                        </Text>
                    </InfoNode>
                    <InfoNode title="Transcribed text">
                        <ActionIcon.Group className={classes.icons}>
                            {!transcribedOpened && (
                                <ActionButton
                                    onClick={transcribedFuncs.open}
                                    label="Show transcribed text"
                                    icon={<IconArrowBarDown stroke={1} />}
                                />
                            )}
                            {transcribedOpened && (
                                <ActionButton
                                    onClick={transcribedFuncs.close}
                                    label="Hide transcribed text"
                                    icon={<IconArrowBarToUp stroke={1} />}
                                />
                            )}
                            <ActionButton
                                onClick={copyTranscribed}
                                label="Copy transcribed text"
                                icon={<IconCopy stroke={1} />}
                            />
                        </ActionIcon.Group>
                        {transcribedOpened && (
                            <Text style={{ whiteSpace: "pre-wrap" }}>{transcription.extractedText}</Text>
                        )}
                    </InfoNode>
                    <InfoNode title="Ai Personality for cleaning">
                        <Text span>{transcription.aiPersonality?.name}</Text>
                    </InfoNode>
                    <InfoNode title="Cleaned text">
                        <ActionIcon.Group className={classes.icons}>
                            {!cleanedOpened && (
                                <ActionButton
                                    onClick={cleanedFuncs.open}
                                    label="Show cleaned text"
                                    icon={<IconArrowBarDown stroke={1} />}
                                />
                            )}
                            {cleanedOpened && (
                                <ActionButton
                                    onClick={cleanedFuncs.close}
                                    label="Hide cleaned text"
                                    icon={<IconArrowBarToUp stroke={1} />}
                                />
                            )}
                            <ActionButton
                                onClick={openDiffModal}
                                label="Show diff"
                                icon={<IconFileDiff stroke={1} />}
                            />
                            <ActionButton
                                onClick={copyCleaned}
                                label="Copy cleaned text"
                                icon={<IconCopy stroke={1} />}
                            />
                        </ActionIcon.Group>
                        {cleanedOpened && <Text style={{ whiteSpace: "pre-wrap" }}>{transcription.cleanedText}</Text>}
                    </InfoNode>
                </>
            );
        }
    }

    return (
        <div className={classes.grid}>
            <Title order={2}>Transcription</Title>
            {main}
            <Link to="..">Back</Link>
        </div>
    );
}

export default Transcription;
