import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

import { type ExternalDeleteMutationVariables, deleteMutation, type ExternalDeleteMutation } from "./queries";

interface IProps {
    external: {
        id: number;
        name: string;
    };
}

function DeleteButton({ external }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<ExternalDeleteMutation, ExternalDeleteMutationVariables>(
        deleteMutation,
    );

    const doDelete = async () => {
        const result = await executeDelete({ externalId: external.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete external access rights",
                message: result.error.message,
            });
        } else {
            const external = result.data!.externalDelete;
            notifications.show({
                title: "User deleted",
                message: `External access right  ${external.name} deleted`,
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete External access right?",
            children: <Text size="sm">Are you sure you want to delete external access right {external.name}?</Text>,
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <Button onClick={onClick} disabled={deleteResult.fetching} color="red">
            Delete
        </Button>
    );
}

export default DeleteButton;
