import { Button, Group, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { z } from "zod";

interface IFormValues {
    content: string;
}

export interface IParsedInput {
    rated_ai_thread: {
        id: number;
        discriminant: string;
        personality: string;
        personality_name: string | null;
        ai_thread_items: {
            id: number;
            role: "user" | "assistant" | "tool";
            content: string;
            tool_name: string | null;
            tool_arguments: string | null;
            hide: boolean;
            rating: "up" | "down" | null;
        }[];
    }[];
}

interface IProps {
    handleNext: (values: IParsedInput) => void;
}

const inputSchema = z.object({
    rated_ai_thread: z.array(
        z.object({
            id: z.number(),
            discriminant: z.string(),
            personality: z.string().uuid(),
            personality_name: z.string().nullable(),
            ai_thread_items: z.array(
                z.object({
                    id: z.number(),
                    role: z.enum(["user", "assistant", "tool"]),
                    content: z.string(),
                    tool_name: z.string().nullable(),
                    tool_arguments: z.string().nullable(),
                    hide: z.boolean(),
                    rating: z.union([z.literal("up"), z.literal("down"), z.null()]),
                }),
            ),
        }),
    ),
});

function parseContent(jsonString: string): IParsedInput {
    return inputSchema.parse(JSON.parse(jsonString));
}

function validateContent(jsonString: string): string | null {
    try {
        parseContent(jsonString);
    } catch (error) {
        if (error instanceof z.ZodError) {
            console.error("Validation errors:", error.errors);
            return `Validation errors: ${JSON.stringify(error.errors)}`;
        }
        console.error("Failed to parse JSON:", error);
        return `Failed to parse JSON: ${error}`;
    }
    return null;
}

function ImportInputForm({ handleNext }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: {
            content: "",
        },
        validate: {
            content: (value) => validateContent(value),
        },
    });

    const handleSubmit = (values: IFormValues) => {
        handleNext(parseContent(values.content));
    };

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Textarea withAsterisk label="JSON" autosize minRows={8} maxRows={16} {...form.getInputProps("content")} />
            <Group justify="flex-end" mt="md">
                <Button type="submit">Next</Button>
            </Group>
        </form>
    );
}

export default ImportInputForm;
