import { gql } from "urql";

// Get Transcription by id query

export const transcriptionQuery = gql`
    query TranscriptionQuery($transcriptionId:Int!) {
        transcription(transcriptionId:$transcriptionId) {
            id
            created
            name
            processingStatus
            extractedText
            cleanedText
            language
            file {
                id
                fileType
                fileName
                src
            }
            aiPersonality {
                id
                name
            }
        }
    }
`;

export type * from "./__generated__/transcription-query";

// Transcription page query

export const transcriptionPageQuery = gql`
    query TranscriptionPageQuery($page:Int!) {
        transcriptionPage(page:$page) {
            items {
                id
                created
                processingStatus
                language
                name
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/transcription-page-query";

// Add Transcription mutation

export const addMutation = gql`
    mutation TranscriptionAddMutation($data:TranscriptionCreateInput!) {
        transcriptionCreate(data:$data) {
            id
            created
            name
            file {
                id
                fileType
                fileName
                src
            }
            aiPersonality {
                id
                name
            }
        }
    }
`;

export type * from "./__generated__/transcription-add-mutation";

// Delete Transcription mutation

export const deleteMutation = gql`
    mutation TranscriptionDeleteMutation($transcriptionId:Int!) {
        transcriptionDelete(transcriptionId:$transcriptionId) {
            id
        }
    }
`;

export type * from "./__generated__/transcription-delete-mutation";

// List ai personalities query

export const aiPersonalityListQuery = gql`
    query AiPersonalityListQuery {
        aiPersonalityList(inChat:false) {
            id
            name
        }
    }
`;

export type * from "./__generated__/ai-personality-list-query";
