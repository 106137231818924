import { Navigate, Route, Routes } from "react-router-dom";
import AiPersonalityRoutes from "./aiPersonalities/AiPersonalityRoutes";
import AppCaches from "./caches/AppCaches";
import DocumentRoutes from "./documents/DocumentRoutes";
import ExternalRoutes from "./externals/ExternalRoutes";
import GenerateImagesRoutes from "./generateImages/GenerateImagesRoutes";
import ThreadRoutes from "./threads/ThreadRoutes";
import TranscriptionsRoutes from "./transcriptions/TranscriptionsRoutes";
import UserRoutes from "./users/UserRoutes";

function AppRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/threads/*" element={<ThreadRoutes />} />
            <Route path="/generate-images/*" element={<GenerateImagesRoutes />} />
            <Route path="/users/*" element={<UserRoutes />} />
            <Route path="/externals/*" element={<ExternalRoutes />} />
            <Route path="/ai-personalities/*" element={<AiPersonalityRoutes />} />
            <Route path="/documents/*" element={<DocumentRoutes />} />
            <Route path="/transcriptions/*" element={<TranscriptionsRoutes />} />
            <Route path="/cache/*" element={<AppCaches />} />
            <Route path="/" element={<Navigate to="threads" />} />
        </Routes>
    );
}

export default AppRoutes;
