import { useMutation } from "urql";
import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";

import { type UserDeleteMutationVariables, deleteMutation, type UserDeleteMutation } from "./queries";

interface IProps {
    user: {
        id: number;
        firstName: string;
        lastName: string;
    };
}

function DeleteButton({ user }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<UserDeleteMutation, UserDeleteMutationVariables>(deleteMutation);

    const doDelete = async () => {
        const result = await executeDelete({ userId: user.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete user",
                message: result.error.message,
            });
        } else {
            const user = result.data!.userDelete;
            notifications.show({
                title: "User deleted",
                message: `User ${user.firstName} ${user.lastName} deleted`,
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete user?",
            children: (
                <Text size="sm">
                    Are you sure you want to delete user {user.firstName} {user.lastName}?
                </Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });

    return (
        <Button onClick={onClick} disabled={deleteResult.fetching} color="red">
            Delete
        </Button>
    );
}

export default DeleteButton;
