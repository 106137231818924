import { useQuery } from "urql";
import { Link, useParams } from "react-router-dom";
import { Box, Title } from "@mantine/core";

import { aiPersonalityQuery, type AiPersonalityQuery, type AiPersonalityQueryVariables } from "./queries";
import Loading from "../../Loading";
import Tune from "./Tune";

function TuneLoader(): JSX.Element {
    const { aiPersonalityId } = useParams();
    const [result] = useQuery<AiPersonalityQuery, AiPersonalityQueryVariables>({
        query: aiPersonalityQuery,
        variables: {
            aiPersonalityId: Number.parseInt(aiPersonalityId!),
        },
    });

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else {
        const aiPersonality = result.data?.aiPersonality;
        if (!aiPersonality) {
            main = <Box>AI personality not found</Box>;
        } else {
            main = <Tune aiPersonality={aiPersonality} />;
        }
    }

    return (
        <Box>
            <Title order={2}>Tune AI personality</Title>
            {main}
            <p>
                <Link to="..">Back</Link>
            </p>
        </Box>
    );
}

export default TuneLoader;
