import { CodeHighlight } from "@mantine/code-highlight";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";

import classes from "./ChatMarkdown.module.css";

interface IProps {
    content: string;
}

function ChatMarkdown({ content }: IProps) {
    return (
        <Markdown
            className={classes.markdown}
            remarkPlugins={[remarkGfm]}
            components={{
                code(props) {
                    const { children, className, node, ...rest } = props;
                    const match = /language-(\w+)/.exec(className || "");
                    if (match) {
                        return <CodeHighlight code={String(children).replace(/\n$/, "")} language={match[1]} />;
                    }
                    return (
                        <code {...rest} className={className}>
                            {children}
                        </code>
                    );
                },
            }}
        >
            {content}
        </Markdown>
    );
}

export default ChatMarkdown;
