import { Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { chunkedUpload } from "../chunkedUploader";
import { getFileType } from "../utils";
import TranscriptionForm, { type IFormValues } from "./TranscriptionForm";
import { type TranscriptionAddMutation, type TranscriptionAddMutationVariables, addMutation } from "./queries";

function Add(): JSX.Element {
    const navigate = useNavigate();
    const [uploading, setUploading] = useState(false);
    const [addResult, executeAdd] = useMutation<TranscriptionAddMutation, TranscriptionAddMutationVariables>(
        addMutation,
    );

    const handleSubmit = async (values: IFormValues) => {
        let storedName: string;
        try {
            setUploading(true);
            storedName = await chunkedUpload({ file: values.file! });
        } catch (error) {
            let message: string;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = "Unknown error";
            }
            notifications.show({
                title: "Failed to upload file",
                message,
                color: "red",
            });
            return;
        } finally {
            setUploading(false);
        }

        const result = await executeAdd({
            data: {
                file: {
                    fileType: getFileType(values.file!)!,
                    fileName: values.file!.name,
                    file: storedName,
                },
                personalityId: Number.parseInt(values.aiPersonality) || null,
                name: values.name,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save Transcription",
                message: result.error.message,
                color: "red",
            });
        } else {
            notifications.show({
                title: "Transcription saved",
                message: "Saved Transcription",
            });
            navigate("..");
        }
    };

    return (
        <>
            <Title order={2}>Create new Transcription</Title>
            <TranscriptionForm handleSubmit={handleSubmit} fetching={addResult.fetching || uploading} />
            <Link to="..">Back</Link>
        </>
    );
}

export default Add;
