import { Route, Routes } from "react-router-dom";

import GenerateImages from "./GenerateImages";
import PreviousImages from "./PreviousImages";

function GenerateImagesRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<GenerateImages />} />
            <Route path="/previous" element={<PreviousImages />} />
        </Routes>
    );
}

export default GenerateImagesRoutes;
