import { Button, FileInput, Group, LoadingOverlay, Select, Stack, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";

import Hider from "../Hider";
import TagsInput from "../TagsInput";
import { DOCUMENT_TYPES, type DocumentType, getMime } from "../utils";

export interface IFormValues {
    documentType: DocumentType;
    name: string;
    content: string;
    file: File | null;
    tags: string[];
    url: string;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    initialValues?: IFormValues;
    edit: boolean;
    fetching: boolean;
    filePlaceholder?: string;
}

const DOCUMENT_FILE_TYPES = ["PDF", "WORD", "POWERPOINT", "AUDIO"];

function DocumentForm({ handleSubmit, initialValues, edit, fetching, filePlaceholder }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: initialValues ?? {
            documentType: "PLAINTEXT",
            name: "",
            content: "",
            url: "",
            file: null,
            tags: [],
        },
        validate: {
            documentType: (value) => (value ? null : "Required"),
            name: (value) => (value ? null : "Required"),
            content: (value, values) => {
                if (values.documentType === "PLAINTEXT" && !value) {
                    return "Required";
                }
                return null;
            },
            url: (value, values) => {
                if (values.documentType === "WEBPAGE" && !value) {
                    return "Required";
                }
                return null;
            },
            file: (value, values) => {
                if (!DOCUMENT_FILE_TYPES.includes(values.documentType)) {
                    return null;
                }
                if (edit) {
                    return null;
                }
                if (!value) {
                    return "Required";
                }
                if (values.documentType === "AUDIO") {
                    if (value.size / 1024 / 1024 > 2000) {
                        return "File size too big";
                    }
                    if (!getMime("AUDIO").includes(value.type)) {
                        return "Unsupported audio format";
                    }
                }
                return null;
            },
        },
    });

    const showFileInput = DOCUMENT_FILE_TYPES.includes(form.values.documentType);

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto">
                <LoadingOverlay visible={fetching} />
                <Hider show={!edit}>
                    <Select
                        withAsterisk
                        label="Document type"
                        data={DOCUMENT_TYPES}
                        {...form.getInputProps("documentType")}
                    />
                </Hider>
                <TextInput withAsterisk label="Name" {...form.getInputProps("name")} />
                <TagsInput form={form} />
                <Hider show={form.values.documentType === "PLAINTEXT"}>
                    <Textarea withAsterisk autosize minRows={3} label="Content" {...form.getInputProps("content")} />
                </Hider>
                <Hider show={form.values.documentType === "WEBPAGE"}>
                    <TextInput withAsterisk label="URL" {...form.getInputProps("url")} />
                </Hider>
                <Hider show={showFileInput}>
                    <FileInput
                        label="Select file"
                        withAsterisk
                        fileInputProps={{ accept: getMime(form.values.documentType).join(",") }}
                        placeholder={filePlaceholder}
                        {...form.getInputProps("file")}
                    />
                </Hider>
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={fetching}>
                        Save
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default DocumentForm;
