import { useForm } from "@mantine/form";

import { Button, Group, LoadingOverlay, TextInput } from "@mantine/core";

export interface IFormValues {
    email: string;
    firstName: string;
    lastName: string;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    initialValues?: IFormValues;
    fetching: boolean;
}

function UserForm({ handleSubmit, initialValues, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: initialValues ?? {
            email: "",
            firstName: "",
            lastName: "",
        },
        validate: {
            email: (value) => (value.includes("@") ? null : "Invalid email"),
            firstName: (value) => (value ? null : "Required"),
            lastName: (value) => (value ? null : "Required"),
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <LoadingOverlay visible={fetching} />
            <TextInput withAsterisk label="Email" {...form.getInputProps("email")} />
            <TextInput withAsterisk label="First name" {...form.getInputProps("firstName")} />
            <TextInput withAsterisk label="Last name" {...form.getInputProps("lastName")} />
            <Group justify="flex-end" mt="md">
                <Button type="submit" disabled={fetching}>
                    Save
                </Button>
            </Group>
        </form>
    );
}

export default UserForm;
