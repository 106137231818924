import { Box, List } from "@mantine/core";

interface IProps {
    item: {
        toolName: string | null;
        toolArguments: string | null;
    };
}

function SearchTool({ item }: IProps) {
    const args: Record<string, string> = JSON.parse(item.toolArguments!);
    let output = `${item.toolName}\n`;
    for (const [key, value] of Object.entries(args)) {
        output += `${key}: ${value}\n`;
    }
    return (
        <Box>
            {item.toolName}
            <List>
                {Object.entries(args).map(([key, value]) => (
                    <List.Item key={key}>
                        {key}: {value}
                    </List.Item>
                ))}
            </List>
        </Box>
    );
}

export default SearchTool;
