import { Pagination, Text, Title } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";

import { buildSearchString } from "./LoadSearch";
import type { IFormValues } from "./SearchForm";
import classes from "./SearchResults.module.css";

interface IProps {
    documents: {
        id: number;
        name: string;
        matchingChunkText: string | null;
    }[];
    searchValues: IFormValues;
    maxPage: number;
    page: number;
}

function SearchResults({ documents, searchValues, maxPage, page }: IProps): JSX.Element {
    const navigate = useNavigate();

    const setPage = (page: number) => {
        navigate({
            pathname: "",
            search: buildSearchString({ ...searchValues, page }),
        });
    };

    return (
        <>
            <Pagination total={maxPage} value={page} onChange={setPage} />
            {documents.map((document) => (
                <Link
                    className={classes.result}
                    to={`../${document.id}${buildSearchString({ ...searchValues, page })}`}
                    key={document.id}
                >
                    <Title order={3}>{document.name}</Title>
                    {document.matchingChunkText && <Text size="sm">{document.matchingChunkText}</Text>}
                </Link>
            ))}
            <Pagination total={maxPage} value={page} onChange={setPage} />
        </>
    );
}

export default SearchResults;
