import { IconTrash } from "@tabler/icons-react";
import { Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { useMutation } from "urql";

import {
    deleteImageMutation,
    type GeneratedImageDeleteMutation,
    type GeneratedImageDeleteMutationVariables,
} from "./queries";
import ActionButton from "../ActionButton";

interface IProps {
    image: {
        id: number;
    };
}

function DeleteButton({ image }: IProps): JSX.Element {
    const [deleteResult, executeDelete] = useMutation<
        GeneratedImageDeleteMutation,
        GeneratedImageDeleteMutationVariables
    >(deleteImageMutation);

    const doDelete = async () => {
        const result = await executeDelete({ generatedImageId: image.id });
        if (result.error) {
            notifications.show({
                title: "Failed to delete image",
                message: result.error.message,
            });
        } else {
            const image = result.data!.generatedImageDelete;
            notifications.show({
                title: "Image deleted",
                message: `User ${image.file!.fileName} deleted`,
            });
        }
    };

    const onClick = () =>
        modals.openConfirmModal({
            title: "Delete image?",
            children: <Text size="sm">Are you sure you want to delete this image?</Text>,
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onConfirm: doDelete,
            confirmProps: { color: "red" },
        });
    return (
        <ActionButton onClick={onClick} label="Delete image" icon={<IconTrash stroke={1} />} size="lg" color="red" />
    );
}

export default DeleteButton;
